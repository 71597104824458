import React, { useState } from "react";
import "./toggle.css";

//define a common component for Toggle button 
//contains useState to manage the on/off state
//function to change value based on the value of state
//return jsx to render the UI of button
const Toggle = ({ onChange, labelLeft, labelRight ,id}) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleToggleChange = () => {
    setIsChecked(!isChecked);
    onChange && onChange(!isChecked);
  };

  return (
    <div className="btn-container">
      <label className="switch btn-color-mode-switch">
        <input
          type="checkbox"
          name="color_mode"
          id={id}
          value="1"
          checked={isChecked}
          onChange={handleToggleChange}
        />
        <label
          htmlFor={id}
          data-on={labelRight}
          data-off={labelLeft}
          className="btn-color-mode-switch-inner"
        ></label>
      </label>
    </div>
  );
};

export default Toggle;//Export to use anywhere in the application
