import React from 'react'
import logo from "../src/assets/images/sharedpro_2.svg";
import './loading.css'


const Loading = () => {
  return (
    <div className='loading-container'>
        <img className="loading-logo" src={logo} alt="loading" />
    </div>
  )
}

export default Loading