import './App.css';
import Footer from './components/Footer/Footer';
import Navbar from './components/Navbar/Navbar';
import About from './Pages/Home/About/About';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from './Pages/Home/Home';
import CaseStudy from './Pages/CaseStudy/CaseStudy';
import CaseStudy1 from './Pages/CaseStudy/ReadMore/indusnet'
import Casestudy2 from './Pages/CaseStudy/ReadMore/iCliQ';
import Blog from './Pages/Blog/Blog';
import BlogDetail from './Pages/Blog/BlogDetail/BlogDetail';
import Termsncondi from './Pages/Terms-and-condi/Terms-and-condi';
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy';
import InformationSecurity from './Pages/InformationSecurity/InformationSecurity';
import ActiveDeals from './Pages/ActiveDeals/ActiveDeals';
import JobDetail from './Pages/ActiveDeals/JobDetails/JobDetail';
import NotFound from './Pages/NotFound/NotFound';
import Loading from './Loading';
function App() {
  
  return (
    <>
      
        <Router>
          <Navbar />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/case-study' element={<CaseStudy/>} />
            <Route path='/indusnet' element={<CaseStudy1/>}/>
            <Route path='/iCliQ' element={<Casestudy2/>}/>
            <Route path='/privacypolicy' element={<PrivacyPolicy/>}/>
            <Route path='/termsncondi' element={<Termsncondi/>}/>
            <Route path='/informationsecurity' element={<InformationSecurity/>}/>
            <Route path='/blog' element={<Blog />} />
            <Route path='/blog-detail' element={<BlogDetail />} />
            <Route path='/job-detail/:jobId' element={<JobDetail />} />
            <Route path='/active-deals' element={<ActiveDeals />} />
            <Route path='/load' element={<Loading />} />

              {/* Add the catch-all route at the end */}
          <Route path='*' element={<NotFound />} />
          </Routes>
          <Footer />
        </Router>
      
    </>
  );
}

export default App;

