import React, { useEffect } from "react";
import PrivacyandTermsHeader from "../../components/PrivacyandTermsHeader/PrivacyandTermsHeader";
import InformationSecurityData from "./InformationSecuritydata/InformationSecuritydata";
import GetStarted from "../../components/GetStarted/getstarted";
//Main component for info-security page
//calls Header,Data and GetStarted for info-security page
const Informationsecurity = () =>{
    // to set the title of the page
    useEffect(()=>{
        document.title = 'Information & Security |Sharedpro';
     },[])
    return(
        <>
         <PrivacyandTermsHeader headerText="Information Security Policy"/>
        <InformationSecurityData/>
        <GetStarted/></>
       
    )
}
export default Informationsecurity;//export to call anywhere in the app