import "./HowItWorks.css";
import React, { useState } from "react";
import { useSpring, animated } from "react-spring";

// The Card component displays information with animation effects.
function Card({ imagen, title, desc, type }) {
  // State to track whether the card is being shown or not.
  const [show, setShown] = useState(false);

  // Define animated styles using react-spring.
  const props3 = useSpring({
    transform: show ? "scale(1.03)" : "scale(1)",
    boxShadow: show
      ? "0 20px 25px rgb(0 0 0 / 25%)"
      : "0 2px 10px rgb(0 0 0 / 8%)"
  });

  return (
    // An animated div for the card with transition effects on hover.
    <animated.div
      className="HowItWorkcard"
      style={props3}
      onMouseEnter={() => setShown(true)}
      onMouseLeave={() => setShown(false)}
    >
      <img src={imagen} alt="HireShare" loading="lazy"/>
      <h2>{title}</h2>
      <p className="howItworkType">{type}</p>
      <p>
        {desc}
      </p>
    </animated.div>
  );
}

export default Card;
