import React from 'react';
import './readmore.css';
import { FaArrowRight } from "react-icons/fa"
// import { useEffect } from 'react';
//Define readmore component function 
//Contains data array for info-card mapping
//
const readmore = () => {
     // to set the title of the page
    //  useEffect(()=>{
    //   document.title = "Indus Net Technologies gets talent deployed from next day|Sharedpro"
    // },[])

  //this array contains details of company info section
  const data = [
    {
      clientName: 'Avg time to deploy:',
      infoCol2Data: '1 Day',
    },
    {
      clientName: 'Talent Hired:',
      infoCol2Data: '8 Talent Hired',
    },
    {
      clientName: 'Partners worked with:',
      infoCol2Data: '6 Partners worked with',
    },
  ];



  return (
    <>
      <div className="readmore-container">
        <div className="readmore-header">
          <div className='header-col1'>
            <img src="https://www.indusnet.co.in/site/wp-content/themes/indusnetblog/assets/images/logo-inverse.svg" alt="" id="readme-img" />
            <div className='header-text'>
              <h2>
              Indus Net Technologies gets talent deployed from next day via Sharedpro
              </h2>
              <p>Indus Net Technologies, a top indian IT firm, needed a solution to help them find immediate joiners. As with many other IT companies, they were hiring talent from the open market resulting in finding talent with long notice periods. On top of this, many didn't join on the joining date. With Sharedpro, they got an unparallel access to talent without notice periods who could be deployed from the next hour itself.</p>
            </div>
          </div>
          <div className='header-col2'>
          </div>
        </div>
        <div className="info-section">
          <div className='info-img'>
            <img src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2084&q=80" alt="" />
          </div>
          <div className='info-card'>
            {data.map((item, index) => (
              <div key={index} className='infocard-col1'>
                <div className='infocard-col2'>
                  <p> <FaArrowRight className='arrow-icon' /> {item.clientName}</p>
                </div>
                <p style={{ borderBottom: '1px solid #999' }}>{item.infoCol2Data}</p>
              </div>
            ))}
          </div>

        </div>

        <div className="content-challenge">
          <div className="card-textcontainer">
            <div className='challenge-text'>
              <h2>01. The Challenge</h2>
              <p style={{ marginBottom: '10px' }}>Indus Net Technologies focuses on an Agile Centric approach to deliver flexible business processes, always ready to counter their challenges with an innovative strategy. To bolster their ever-growing client requirements, hiring talent with notice periods was proving to be a bottleneck for them.</p>
              <p style={{ marginBottom: '10px' }}>“Today, even after rolling out offer letters, there is no guarantee that the selected candidates will join your organization on the joining date. And in today’s fast-paced competitive environment, there cannot be a room for uncertainty.“ - Abhishek Rungta, CEO, Indus Net Technologies.</p>
              <p style={{ marginBottom: '10px' }}>It takes anywhere between 3 to 6 months to deploy a permanent employee onto a project after considering the time taken for sourcing, interviewing, notice periods, etc. Add to this the hassle of repeating the entire process if the selected candidate didn’t join. INT was looking for a solution to fill this gap and reduce the time to deploy talent to minimal.</p>
            </div>
            <div className='card-container'>
              <img src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2080&q=80" alt="" />
              <h4 className='card-title'>About IndusNet</h4>
              <p>Indus Net Technologies (INT) is a Global Award Winning IT Outsourcing Service Provider. They provide web & mobile app development, digital marketing & cloud-based services.
              </p>
              <ul>
                <li>
                  <div className='about-list1'>
                    <h4>Industry</h4>
                    <p>Outshoring/Offsoring</p>
                  </div>
                </li>
                <li><div className='about-list'>
                  <h4>Company Size</h4>
                  <p>500-100 employees</p>
                </div></li>
                <li><div className='about-list3'>
                  <h4>Headquaters</h4>
                  <p>Kolkata/India</p>
                </div></li>
              </ul>
            </div>
          </div>
          <div className='challenge-card'>
            <div>
              <div className="challenge-img">
                <div className='image1'>
                  <img src="https://plus.unsplash.com/premium_photo-1661375427386-64ac35086427?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80" alt="" />
                </div>
                <div className='image2'>
                  <img src="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80" alt="" />
                </div>
              </div>
              <div className='process-text'>
                <h2>02. Our Process</h2>
                <p>INT Team collaborated with Sharedpro to shorten the process of deploying talent on their projects. Sharedpro managed to help INT in mainly three ways.</p>
                <ul className='process-list'>
                  <li>
                    <div className="list-title">
                      <img src="https://demo.egenslab.com/html/corelaw/preview/assets/images/icons/list-arrow.svg" alt="" width={'40px'} height={'40px'} />
                      <h3>Access to talent without notice period</h3>
                    </div>
                    <p>With Sharedpro, INT added a new and unique channel to source talent employed as permanent employees in various companies across India. This talent pool was pre-vetted, well trained, and best of all, they could be deployed immediately.</p>
                    <p>This talent pool would be waiting for a new project in their current company after completing the previous project. INT leveraged the Sharedpro's network of supply partners to access this talent pool who could start working from the next hour itself.</p>
                  </li>
                  <li>
                    <div className="list-title">
                      <img src="https://demo.egenslab.com/html/corelaw/preview/assets/images/icons/list-arrow.svg" alt="" width={'40px'} height={'40px'} />
                      <h3>Passive talent unavailable in the open market</h3>
                    </div>
                    <p>The candidates sourced by Staffing firms are active talent, i.e., sourced from job boards like Naukri, Monster, indeed and many more. Multiple companies end up fighting for the same talent resulting in problems like talent not joining on the joining date.</p>
                    <p>With Sharedpro, INT got access to passive talent who might not be looking for a job actively on job boards. Hence, a lot of uncertainties associated with hiring talent from the open market were ruled out.</p>
                  </li>
                  <li>
                    <div className="list-title">
                      <img src="https://demo.egenslab.com/html/corelaw/preview/assets/images/icons/list-arrow.svg" alt="" width={'40px'} height={'40px'} />
                      <h3>Single agreement to work with multiple vendors</h3>
                    </div>
                    <p>Companies typically have to sign multiple contracts to work with various vendors resulting in a tedious process. While working with Sharedpro, INT had to sign a single agreement to work with multiple vendors or supply partners across India.</p>
                    <p>Before onboarding a supply partner, Sharedpro takes care of all legal formalities, negotiations, and vetting processes.</p>
                  </li>
                </ul>
              </div>
            </div>
            <div>
            <div className='CTA-card'>
              <img src="" alt="" />
              <h3>Start growing with Sharedpro today</h3>
              <p>With tools to find talent without notice period and a support team eager to help you, make your company more competitive</p>
              <button className='CTA-button'>Get Sharedpro Free</button>
            </div>
            </div>
          </div>

          <div className='result-text'>
            <div className='challenge-text'>
              <h2>03. The Result.</h2>
              <p>Sharedpro’s talent pool acted as a bench for Indus Net Technologies, helping them reduce the time taken to deploy a talent from months to hours.</p>
              <br />
              <p>The INT team hired certified developers for technologies like Magento, Android, Angular, QA, Dot Net, etc., while reducing thier average time of deploying talent to one day after getting associated to Sharedpro. They could now work with a diversified pool of qualified talent employed in various IT companies of Tier 2/3 cities giving their team an edge to win over their competitors.</p>
            </div>
            <div className="challenge-img">
              <div className='image1'>
                <img src="https://plus.unsplash.com/premium_photo-1661375427386-64ac35086427?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80" alt="" />
              </div>
              <div className='image2'>
                <img src="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80" alt="" />
              </div>
            </div>
          </div>
          <div className='customerwords-card'>
                <div className='customer-img'>
                  <img src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2080&q=80" alt="" />
                </div>
                <div className='customer-text'>
                  <p>Sharedpro has furnished a stellar number of candidates from sources not explored earlier in no time, taking it upon themselves to provide us with a hassle-free experience with the single contract system incorporated</p>
                  <h4>Arijit Das, Sr. Executive - HR, Indus Net Technologies</h4>
                </div>
            </div>
            <div className='CTA-resposive'>
              <img src="" alt="" />
              <h3>Start growing with Sharedpro today</h3>
              <p>With tools to find talent without notice period and a support team eager to help you, make your company more competitive</p>
              <button className='CTA-button'>Get Sharedpro Free</button>
            </div>
        </div>
      </div>
    </>
  );
}

export default readmore;//Export to call anywhere in the application


