import React, { useContext, useEffect } from 'react'
import './card.css'
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { CasestudyContext } from '../../../ContextAPI/CasestudyContext';


//Initialize AOS library for animations when the component mounts
//Define functional component for caseStudy card section
//Return jsx for UI render This the card component for caseStudy  

const Card = () => {
  useEffect(() => {
    AOS.init();
  }, [])

  const { casestudyValue,setcasestudyValue } = useContext(CasestudyContext);
  const handleReadMore1Click = () => {
    setcasestudyValue('caseStudy1');
    console.log('button clicked');
    console.log(casestudyValue);
  };
  const handleReadMore2Click = () => {
    setcasestudyValue('caseStudy2');
    console.log('button clicked');
    console.log(casestudyValue);
  };
 
  return (
    <>
      <h1 className='case-study-heading'>Latest Case</h1>
      <div className="case-card-study-contianer">
        <div className="case-study__wrp">
          <div className="case-study-card-item" data-aos="zoom-in">
            <div className="case-study__img">
              <img src="https://uploads-ssl.webflow.com/624fcc252da9f40edc73be71/625cf00b37119efbeb334142_svgexport-1.svg" alt="" />
            </div>
            <div className="case-study__content">
              <div className="case-study__text">Indus Net Technologies, a top indian IT firm, needed a solution to help them find immediate joiners. As with many other IT companies, they were hiring talent from the open market resulting in finding talent with long notice periods</div>
              <Link to="/indusnet">
                <a className="btn btn__blue get_started rotate-on-hover" onClick={handleReadMore1Click}>
                  Read More <FaArrowRight className="icon" />
                </a>
              </Link>
            </div>
          </div>
          <div className="case-study-card-item" data-aos="zoom-in">
            <div className="case-study__img">
              <img src="https://develop.sharedpro.in/images/icliq.svg" alt="" />
            </div>
            <div className="case-study__content">
              <div className="case-study__text">iCliQ Solution, an Indian outsourcing company providing IT solutions and services, was looking to work for long term enterprise projects. Working on smaller projects comes up with a lot of uncertainties</div>
              <Link to="/iCliQ">
                <a className="btn btn__blue get_started rotate-on-hover" onClick={handleReadMore2Click}>
                  Read More <FaArrowRight className="icon" />
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>


  )
}

export default Card;
