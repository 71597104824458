import React, { useEffect } from "react";
import PrivacyandTermsHeader from "../../components/PrivacyandTermsHeader/PrivacyandTermsHeader";
import Termsdata from './TermsData/termsdata';
import GetStarted from "../../components/GetStarted/getstarted";

//define main component for privacypolicy page
//returns all the required components for the page
const Termsncondi = () =>{
      // to set the title of the page
      useEffect(()=>{
        document.title = 'Terms & Conditions |Sharedpro';
     },[])
    return(
        <>
            <PrivacyandTermsHeader headerText="Term Of Use"/>
            <Termsdata/>
            <GetStarted/>
        </>
    )
}
export default Termsncondi;//Export to call anywhere in the application