import React from 'react'
import './notfound.css'
import { useEffect } from 'react';

const NotFound = () => {
      // to set the title of the page
      useEffect(()=>{
        document.title = '404 Not Found |Sharedpro';
      },[])
  return (
    <div className="Notfoundcontainer ">
        <h1>404! Page Not Found</h1>

  <h3>
  <a className='btn btn__blue' onClick={() => window.history.back()}>Go Back</a>

  </h3>
</div>

  )
}

export default NotFound