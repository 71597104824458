import React, { useState } from 'react'
import './ShareTalent.css'
import Toggle from '../../../components/Toggle/Toggle';
import Card from './cardSection/Card';
import Immediate_joiners from '../../../assets/images/Immediate_joiners.png'
import Joining_ratio from '../../../assets/images/Joining_ratio.png'
import Passive_Talent from '../../../assets/images/Passive_Talent.png'
import Urgent_Projects from '../../../assets/images/Urgent_Projects.png'
import Steady_Income from '../../../assets/images/Steady_Income.png'
import More_Exposure from '../../../assets/images/More_Exposure.png'


//array to show the hire talent data 
const hire = {
    sample: [
      {
        title: 'Immediate joiners',
        description: 'Hire from a pool of readily deployable talent employed in 250+ companies',
        imageUrl: Immediate_joiners,
      },
      {
        title: 'Joining ratio',
        description: 'Ensure a joining ratio of 98% for all the candidates selected via Sharedpro',
        imageUrl: Joining_ratio,
      },
      {
        title: 'Passive Talent',
        description: 'Reduce duplicate profiles by accessing talent unavailable in the open market',
        imageUrl: Passive_Talent,
      },

    ],
  };

  // array to show the share talent data 
const share = {
    sample: [
      {
        title: 'Urgent Projects',
        description: 'Reduce your talent underutilization rate by deploying talent on urgent projects',
        imageUrl: Urgent_Projects,
      },
      {
        title: 'More Exposure',
        description: 'Work with big clients unavailable on freelancing sites while reducing dependence on sales executives',
        imageUrl: More_Exposure,
      },
      {
        title: 'Steady Income',
        description: 'Grow your company with longer projects of 3-6 months',
        imageUrl: Steady_Income,
      },
      
    ],
  };

// This is the share talent functional component.
  const ShareTalent = () => {
    const [isToggleOn, setIsToggleOn] = useState(false);
  
    const handleToggleChange = () => {
      setIsToggleOn(!isToggleOn);
    };
  
    const dataToDisplay = isToggleOn ? share : hire;
  
    return (
      <div className='share_talent_section'>
        <h1>
          Why{" "}
          <span style={{ display: "inline-block", verticalAlign: "middle" }}>
            <Toggle onChange={handleToggleChange} width={100} labelLeft="Hire" labelRight="Share" id="sharetalent" />
          </span>{" "}
          talent via Sharedpro
        </h1>
  
        <div className='card-section flex__center'>
          {dataToDisplay.sample.map((item, index) => (
            <Card
              key={index}
              title={item.title}
              description={item.description}
              imageUrl={item.imageUrl}
            />
          ))}
        </div>
      </div>
    );
  }
  
  export default ShareTalent;
  