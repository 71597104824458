import React from "react";
import './InformationSecuritydata.css'
import PrivacyandTermsCard from "../../../components/PrivacyandTermsCard/PrivacyandTermsCard";

//define function for info-security page
//returns jsx for UI render
//calls PrivacyTermsCard component 
const informationsecuritydata = () => {
    return (
        <>
            <div className="cardntext">
                <div>
                    <PrivacyandTermsCard />
                </div>
                <div className="infosecuritytext">
                    <div className="infosecurityIntro">
                        <h3>Introduction</h3>
                        <p>Sharedpro recognizes the importance of information security and is committed to ensuring the confidentiality, integrity, and availability of all information assets. This policy outlines a framework for setting objectives, supporting commitment to meeting requirements, and continuous improvement to ensure the ongoing effectiveness of our information security program.</p>
                    </div>
                    <div className="infoobjective">
                        <h3>Objective</h3>
                        <li> <h4>Confidential</h4><p>To ensure that confidential information is protected from unauthorized disclosure, access, or use.</p></li>
                        <li><h4>Integrity</h4><p>To ensure that confidential information is protected from unauthorized disclosure, access, or use.</p></li>
                        <li><h4>Avaliability</h4><p>To ensure that confidential information is protected from unauthorized disclosure, access, or use.</p></li>
                        <li><h4>Compailence</h4><p>To ensure that confidential information is protected from unauthorized disclosure, access, or use.</p></li>
                        <li><h4>Awareness</h4><p>To ensure that confidential information is protected from unauthorized disclosure, access, or use.</p></li>
                    </div>
                </div>
            </div>
        </>


    )
}
export default informationsecuritydata;//Export to call anywhere in the application