import React, { useState, useEffect} from 'react';
import './activedeals.css';
import { BsSearch, BsFilter } from 'react-icons/bs';
import axios from 'axios';
import Card from './card/Card';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from '../../Loading'
 
const ActiveDeals = () => {
 
  useEffect(() => {
    document.title = 'Active Deals|Sharedpro';
  }, []);
 
  const [isFilterOpen, setIsFilterOpen] = useState(false);// to open and close the filter option 
  const [placeholderText, setPlaceholderText] = useState('Search by Job ID');
  const [searchText, setSearchText] = useState('');
  const [data, setData] = useState([]); // stores the array of current page data  
  const itemsPerPage = 10; // number of active deals on a page 
  const [totalCount, setTotalCount] = useState(''); // stores count of total number of products e.g :- 317


  // function to load more data on scroll and merge the previous page data with the next page 
  const getActiveDeals = async ()=>{
    const apiUrl = `https://develop.sharedpro.in/getActiveJobs`; // rest api of active jobs data 
    const pageNo = Math.floor(data.length / itemsPerPage);  // logic to change next page number 
    const queryParam = "?page="+ pageNo + "&size=" + itemsPerPage // to append the pageno and item limit per page 
    const finalUrl = apiUrl + queryParam    


    await axios
      .get(finalUrl)
      .then((response) => {
        const apiRes = response.data.data?.activeJobsList;
        const mergeData = [...data, ...apiRes] // merge the prev page and next page data  
        setData(mergeData); // set the combined data to data variable 
        setTotalCount(response.data.data.totalRecords); // set the count of totalNumber of record (e.g:- 317)
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }

  useEffect(() => {
    getActiveDeals()
  }, []);
 
 
 

  const [filteredData, setFilteredData] = useState([]);
  const [noDataFound, setNoDataFound] = useState(false); // state to handel if no data found 


  // function to filter the data on the basis of specific field 
  const filterData = (filterType) => {
    if (!searchText) {
      return;
    }
    let apiUrl = '';
 
    if (filterType === 'Specialization') {
      apiUrl = `https://develop.sharedpro.in/getActiveJobsByJobSpecialization/${searchText}`;
    } else if (filterType === 'Location') {
      apiUrl = `https://develop.sharedpro.in/getActiveJobsByJobLocation/${searchText}`;
    } else if (filterType === 'Job ID') {
      apiUrl = `https://develop.sharedpro.in/getActiveJobsByJobId/${searchText}`;
    } else if (filterType === 'Skills') {
      apiUrl = `https://develop.sharedpro.in/getActiveJobsByJobSkills/${searchText}`;
    }
 
    // set loading to true 
    setLoading(true);

    axios
      .get(apiUrl)
      .then((response) => {
        const newData = response.data.data.activeJobsList;
        setFilteredData(newData)

        // Check if there is no data
        if (!newData || newData.length === 0) {
          setNoDataFound(true);
        } else {
          setNoDataFound(false);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      })
      .finally(() => {
        // Stop loading regardless of success or failure
        setLoading(false);
      });
  };
 
  useEffect(() => {
    //spliting the 'search by'  from placeholder and sending the filtertype 
    //(i.e:- loaction, jobId,Specialization and skill)
    filterData(placeholderText.split('Search by ')[1]);
  }, [searchText, placeholderText]);

  
  // to handle the filter toggle 
  const handleFilterToggle = () => {
    setIsFilterOpen(!isFilterOpen);
  };
 

  // function to change the placeholder text from dropdown 
  const handleFilterChange = (filterType) => {
    setPlaceholderText(`Search by ${filterType}`);
    setIsFilterOpen(false);
  };
 

  const [isSearchBarFixed, setIsSearchBarFixed] = useState(false); // state to fix the search bar on scroll
  const [isFilterDropdownFixed, setIsFilterDropdownFixed] = useState(false); // state to fix the drop-down box bar on scroll
  const [loading, setLoading] = useState(false); // state for loading...


  useEffect(() => {
    // function to fix the search-bar and drop-down on scroll 
    const handleScroll = () => {
      const navbarHeight = document.querySelector('.navbar').offsetHeight; // calculate the navbar height

      //condition to fix the search-bar and drop-down below the navbar when user scroll
      if (window.scrollY > navbarHeight) {
        setIsSearchBarFixed(true);
        setIsFilterDropdownFixed(true);
      } else {
        setIsSearchBarFixed(false);
        setIsFilterDropdownFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const searchBarClassName = `active-deal-search-bar ${isSearchBarFixed ? 'fixed' : ''}`; // added fixed class for fixed serach-bar
  const filterDropdownClassName = `filter-dropdown ${isFilterDropdownFixed ? 'fixed' : ''}`; // added fixed class for fixed drop-down

  return (
    <div className="active-deals-container">
      <div className="active-deal-header flex__center">
        <div className="active-deal-title">
          <h1>Active Deals for Today | Sharedpro</h1>
          <p>We are seeking talented people</p>
        </div>
      </div>
 
      <div className="active-deal-search-bar flex__center">
       <div className={searchBarClassName}>
       <div className="input-box-section">
          <BsSearch className="active-deal-search-icon" />
          <input
            type="text"
            className="active-deal-search-box"
            placeholder={placeholderText}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <div className="filter-icon" onClick={handleFilterToggle}>
            <BsFilter />
          </div>
        </div>
       </div>
 
        {isFilterOpen && (
          <div className="filter-dropdown">
            <div className={filterDropdownClassName}>
            <ul>
              <li onClick={() => handleFilterChange("Specialization")}>
                Search by Specialization
              </li>
              <li onClick={() => handleFilterChange("Location")}>
                Search by Location
              </li>
              <li onClick={() => handleFilterChange("Job ID")}>
                Search by Job ID
              </li>
              <li onClick={() => handleFilterChange("Skills")}>
                Search by Skills
              </li>
            </ul>
            </div>
          </div>
        )}
      </div>
 
      <InfiniteScroll
    dataLength={data.length}
    next={getActiveDeals}
    hasMore={data.length < totalCount  }
    loader={loading ? <Loading /> : null} 
    scrollableTarget="active-deal-scroll-container"
  >

      {/* sending props conditionally 
      if no data found then it will send empty array
      if data then it will Check for filtered data 
      if no filtered data found then it will send whole data */}
     <Card data={noDataFound ? [] : (filteredData.length > 0 ? filteredData : data)} />
  </InfiniteScroll> 

  {/* if no data found then it will render  */}
  {noDataFound && <h3 className='noDataFound'>No Data Found!</h3>}

    </div>
  );
};
 
export default ActiveDeals;