import React from 'react'
import './Testimonials.css'
import testimonialimg from '../../../assets/images/testimonial.jpg';


//Define functional component for Testimonial section
//Return jsx for UI render

const Testimonials
= () => {
  return (
    <div className="testimonials">
        <div className="container">
            <div className="testimonials__content text__center text__light flex flex__center">
                <div className="testimonials__content--left">
                <img src={testimonialimg} alt="testimonial" loading="lazy"/>
                </div>
                <div className="testimonials__content--right">
                    <h1 className="testimonials__title fw__6">Testimonials</h1>
                    <p className="para__text">Sharedpro has furnished a stellar number of candidates from sources not explored earlier in no time, taking it upon themselves to provide us with a hassle-free experience with the single contract system incorporated.</p>
                    <div className="testimonials_profile">
                        <img
                      src={testimonialimg}
                      alt="profile"
                      className="profile-image" 
                      loading="lazy"
                        />
                        <div className="profile-content">
                        <h2 className="profile-name">Arijit Das</h2>
                        <p className="profile-company">Indus Net Technologies</p>
                        </div>
                        </div>

                </div>
            </div>
        </div>
    </div>
  )
}




export default Testimonials
