// import React, { useEffect } from 'react'
import './blog.css'
// import {BsChevronDoubleRight} from "react-icons/bs";
import Pagination from '../../components/pagination/Pagination';
import { useEffect, useState } from 'react';
import PostItem from './PostItem';
import { Link } from 'react-router-dom';
// import Metadata from '../../components/Navbar/Metadata';



//popular post data 
const popularPostsData = [
  {
    imageUrl:
      "https://paradox-react.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fblog_image_8.fe71b0e3.jpg&w=750&q=75",
    date: "Sep 12, 2019",
    title: "Why great employer branding attracts better talent?",
  },
  {
    imageUrl:
      "https://paradox-react.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fblog_image_8.fe71b0e3.jpg&w=750&q=75",
    date: "Sep 12, 2019",
    title: "Why great employer branding attracts better talent?",
  },
  {
    imageUrl:
      "https://paradox-react.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fblog_image_8.fe71b0e3.jpg&w=750&q=75",
    date: "Sep 12, 2019",
    title: "Why great employer branding attracts better talent?",
  },
  {
    imageUrl:
      "https://paradox-react.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fblog_image_8.fe71b0e3.jpg&w=750&q=75",
    date: "Sep 12, 2019",
    title: "Why great employer branding attracts better talent?",
  },
  {
    imageUrl:
      "https://paradox-react.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fblog_image_8.fe71b0e3.jpg&w=750&q=75",
    date: "Sep 12, 2019",
    title: "Why great employer branding attracts better talent?",
  },
  {
    imageUrl:
      "https://paradox-react.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fblog_image_8.fe71b0e3.jpg&w=750&q=75",
    date: "Sep 12, 2019",
    title: "Why great employer branding attracts better talent?",
  },
  {
    imageUrl:
      "https://paradox-react.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fblog_image_8.fe71b0e3.jpg&w=750&q=75",
    date: "Sep 12, 2019",
    title: "Why great employer branding attracts better talent?",
  },
];


// posted blog data 
const postItemsData = [
  {
    imageUrl: 'https://prod-sharedpro-in.s3.ap-south-1.amazonaws.com/blog-images/1689069723790-MicrosoftTeams-image_(3).png',
    author: 'Urmi Jain',
    date: 'Sep 12, 2019',
    title: 'CIIE.CO, BMGF, NPCI Launch Grand Challenge To Enable Digital Payments For Feature Phones',
  },
  {
    imageUrl: 'https://prod-sharedpro-in.s3.ap-south-1.amazonaws.com/blog-images/1674115591928-edited_2.png',
    author: 'Urmi Jain',
    date: 'Sep 12, 2019',
    title: 'CIIE.CO, BMGF, NPCI Launch Grand Challenge To Enable Digital Payments For Feature Phones',
  },
  {
    imageUrl: 'https://prod-sharedpro-in.s3.ap-south-1.amazonaws.com/blog-images/1584090697440-covid_response_sharedpro.jpg',
    author: 'Urmi Jain',
    date: 'Sep 12, 2019',
    title: 'CIIE.CO, BMGF, NPCI Launch Grand Challenge To Enable Digital Payments For Feature Phones',
  },
  {
    imageUrl: 'https://paradox-react.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fblog_image_1.38f32bce.jpg&w=1200&q=75',
    author: 'Urmi Jain',
    date: 'Sep 12, 2019',
    title: 'CIIE.CO, BMGF, NPCI Launch Grand Challenge To Enable Digital Payments For Feature Phones',
  },
  {
    imageUrl: 'https://prod-sharedpro-in.s3.ap-south-1.amazonaws.com/blog-images/1576388784517-social_media_recruiting.jpg',
    author: 'Urmi Jain',
    date: 'Sep 12, 2019',
    title: 'CIIE.CO, BMGF, NPCI Launch Grand Challenge To Enable Digital Payments For Feature Phones',
  },
  {
    imageUrl: 'https://paradox-react.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fblog_image_9.cb3a1f26.jpg&w=750&q=75',
    author: 'Urmi Jain',
    date: 'Sep 12, 2019',
    title: 'CIIE.CO, BMGF, NPCI Launch Grand Challenge To Enable Digital Payments For Feature Phones',
  },
  {
    imageUrl: 'https://paradox-react.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fblog_image_9.cb3a1f26.jpg&w=750&q=75',
    author: 'Urmi Jain',
    date: 'Sep 12, 2019',
    title: 'CIIE.CO, BMGF, NPCI Launch Grand Challenge To Enable Digital Payments For Feature Phones',
  },
  {
    imageUrl: 'https://paradox-react.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fblog_image_9.cb3a1f26.jpg&w=750&q=75',
    author: 'Urmi Jain',
    date: 'Sep 12, 2019',
    title: 'CIIE.CO, BMGF, NPCI Launch Grand Challenge To Enable Digital Payments For Feature Phones',
  },
];




// functional component for popular posts section.
const PopularPost = ({ imageUrl, date, title }) => (
  <div className="popular-post-item">
    <div className="img-wrapper">
      <img src={imageUrl} alt="popular-post" />
    </div>
    <div className="popular-post-content">
      <p className='blog-date'>{date}</p>
      <p>{title}</p>
    </div>
  </div>
);


//Define functional component for Blog page
//Return jsx for UI render

const Blog = () => {

  // to set the title of the page
  useEffect(()=>{
    document.title = 'Blog|Sharedpro';
  },[])

  // <Metadata title="Blog|Sharedpro" />


  const itemsPerPage = 4; 
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };




  //This is for demo purpose to call the data from the backend.

  // const [postItemsData, setPostItemsData] = useState([]); 

  // useEffect(() => {
  //   fetch('http://localhost:8080/posts')
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setPostItemsData(data); 
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching data:', error);
  //     });
  // }, []);



  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedItems = postItemsData.slice(startIndex, endIndex);


  //function to redirect to top of the page
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };

  return (
    <div className="blog-container">
    <div className="blog-header flex__center">
    <div className="blog-title">
        <h1>Blogs</h1>
    </div>
    </div>

<div className="bolg-main-section">
  <div className='blog-left-sidebar'>
    <div className="from-the-blog-section">
      <h3>From The Blog</h3>
      <p>We create amazing Webflow templates for creative people all around the world and help brands stand out.</p>
  </div>
  <div className="popular-posts">
  <h3>Popular Post</h3>
  <div className="popular-post-container">
  {popularPostsData.map((post, index) => (
                  <PopularPost
                    key={index}
                    imageUrl={post.imageUrl}
                    date={post.date}
                    title={post.title}
                  />
  ))}
  </div>
  </div>
    </div>

   {/* right-side-section */}
   <div className='blog-right-section'>
   <Link to="/blog-detail"
   onClick={scrollToTop}>

    <div className="post-items-container">
  {displayedItems.map((post, index) => (

          // posted Item component 
          <PostItem
            key={index}
            imageUrl={post.imageUrl}
            author={post.author}
            date={post.date}
            title={post.title}
          />
        ))}
    </div>
    </Link>
   </div>
 </div>
 
 {/* pagination component  */}
 <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={postItemsData.length}
        onPageChange={handlePageChange}
      />
</div>
  )
}

export default Blog
