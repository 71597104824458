import React from 'react'
import './newsSection.css'

const NewsSection = ({images}) => {
    if (!images) {
        return null;
      }
  return (
    <div className="news-section flex__center">
    {images.map((imageUrl, index) => (
      <div className="news-image" key={index}>
        <img
          src={imageUrl}
          alt={`news-${index}`}
          loading="lazy"
        />
      </div>
    ))}
  </div>
  )
}

export default NewsSection