import React from "react";
import "./subscribe.css";
import subimg from "../../assets/images/sub-img.svg";
import NewsSection from "../NewsSection/NewsSection";
//Define functional Component for Subscribe section
//reuturn the jsx to render UI 
// contains Client Componet 
// which is getting its prop values form clientImage array

const Subscribe = () => {
  const ClientndNewsImages = [
    "https://develop.sharedpro.in/images/yourstory%20(1).svg",
    "https://develop.sharedpro.in/images/business.svg",
    "https://develop.sharedpro.in/images/in24.svg"
  ];
  return (
    <>
      <div>
        <NewsSection images={ClientndNewsImages}/>
        <div className="main-div">
          <div className="subscribe-section">
            <div className="explore-text">
              <h2>Subscribe to Our Newsletter</h2>
              <p id="explore-p">Get weekly updates about our product in your email, no spam guaranteed, we promise ✌️</p>
            </div>
            <form>
              <div className="subscribe-inputfield-container">
                <input
                  className="subscribe-inputfield"
                  name="email"
                  placeholder="your@company.com"
                  type="text"
                />
                <input
                  className="inputfield-btn"
                  value="Subscribe Now!"
                  type="submit"
                />
              </div>
            </form>
          </div>
          <div className="second-div">
            <img src={subimg} alt="img" className="sub-img"  loading="lazy" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscribe;//Export the function inorder to call it anywhere in application
