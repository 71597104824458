import React, { useState } from "react";
import "./HireTalent.css";
import hiretalent from "../../../assets/images/Hire.png";
import Toggle from "../../../components/Toggle/Toggle";
import ClientndNews from "../../../components/ClientndNews/clientNdnews";
import {FaArrowRight} from "react-icons/fa";
import logo1 from '../../../assets/images/smartcloud-logo.svg';
import logo2 from '../../../assets/images/orr2.svg';
import logo3 from '../../../assets/images/HappiestMinds_Logo.svg';
import logo4 from '../../../assets/images/gignex.svg';
import logo5 from '../../../assets/images/int.svg';

//Define functional component for HireTalent section
//Return jsx for UI render
//Contains ClientndNewsImages array for ClientndNews component prop
//useState to toggle between Hire and Share Text

const HireTalent = () => {
    const [isToggleOn, setIsToggleOn] = useState(false);
    const ClientndNewsImages = [
        logo1,
        logo2,
        logo3,
        logo4,
        logo5,
      ];


    return (
        <>
            <div className="hire-client flex__center">
                <ClientndNews images={ClientndNewsImages} />
                <div className="hiretalent ">
                    <div className="text-col">
                        <Toggle onChange={() => setIsToggleOn(!isToggleOn)} labelLeft="Hire" labelRight="Share" id="hire" />
                        <div className="text-row-1">
                            {isToggleOn ? (
                                <div className="hiretalentani">
                                    Hire talent without notice period
                                </div>
                            ) : (
                                <>
                                    Reduce underutilization rate
                                </>
                            )}
                        </div>
                        <div className="text-row-2">
                            {isToggleOn ? (
                                <>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap.
                                </>
                            ) : (
                                <>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                </>
                            )}
                        </div>
                        <br />
                        <a href="#" className="btn btn__blue rotate-on-hover">
        Post Job <FaArrowRight className="icon"/>
    </a>
                     
                    </div>
                    <div className="img-col-2">
                        <img src={hiretalent} alt="Hire Talent" loading="lazy"/>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HireTalent;
