import React from 'react'
import Header from './Header/Header'
import HireTalent from './HireTalent/HireTalent'
import ShareTalent from "./whyUs/ShareTalent"
import About from './About/About'
// import { useContext, useEffect, useState } from 'react';
import Testimonials from './Testimonials/Testimonials'
import Subscribe from '../../components/subscribe/Subscribe'
import HowItWorks from './HowItWorks/HowItWorks'
import { useEffect } from 'react'
// import SplashScreen from '../../SplashScreen';
// import { Link } from 'react-router-dom'

//Homepage component to intergrate all the compoentns of HomePage
//define the functional component 
//Includes function for splashScreen
const Home = () => {

    // to set the title of the page
    useEffect(()=>{
      document.title = "Deploy IT consultants within 24 hours | SharedPro";
    },[])
  // const [splashFinished, setSplashFinished] = useState(false);

  // const handleSplashScreenFinish = () => {
  //   setSplashFinished(true);
  // };
  
  return (
    <div>

      {/* splash-screen code */}
      {/* <SplashScreen
        duration={2000}
        onSplashScreenFinish={handleSplashScreenFinish}
      />
      <div className="holder">
        {splashFinished && (
          <div>
          </div>
        )}
      </div> */}
      <Header />
      {/* <Link to="/job-detail">
        <button>go</button>
      </Link>
      <Link to="/active-deals">
        <button>go</button>
      </Link> */}
      <HireTalent />
      <ShareTalent />
      <About />
      <HowItWorks />
      <Testimonials />
      <Subscribe />
    </div>
  )
}

export default Home//Export to call anywhere in application