import { createContext, useState } from "react";
export const CasestudyContext = createContext("");
export const CasestudyProvider = ({children}) => {
    const [casestudyValue,setcasestudyValue] = useState(false);
    return(
        <CasestudyContext.Provider value={{casestudyValue,setcasestudyValue}}>
            {children}
        </CasestudyContext.Provider>
    )
}
 