import React, { useEffect } from 'react';
import './about.css'; 
import AOS from 'aos'; // Import the AOS library for animations
import 'aos/dist/aos.css'; // Import the AOS CSS for animations

// Define a functional component for "About"
// Initialize AOS library for animations when the component mounts
// The component's return statement defines its JSX structure
const About = () => {
    useEffect(() => {
        AOS.init(); 
    }, []);

    return (
        <div className="about">
            <div className="container">
                <div className="about__content text__center text__light flex flex__center">
                    <div className="about__content--left" data-aos="zoom-in">
                        <div id="circle-orbit-container">
                            <ul>
                                <li className="inner-circle"></li>
                                <li className="orbit-circle flex__center">
                                    <p>hello</p>
                                </li>
                                <li className="orbit-circle flex__center">
                                    <p>hello</p>
                                </li>
                                <li className="orbit-circle flex__center">
                                    <p>hello</p>
                                </li>
                            </ul>
                        </div>
                        <div id='outer-orbit'></div>
                    </div>
                    <div className="about__content--right" data-aos="zoom-in">
                        <h1 className="about__title fw__6">Know About us</h1>
                        <p className="para__text">We are a new design studio based in the USA. We have over 20 years of combined experience and know a thing or two about designing websites and mobile apps.</p>
                        <a href="#" className="btn btn__blue">Know More</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About; // Export the "About" component for use in other parts of the application
