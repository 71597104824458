import React from "react";
import PrivacyData from "./PrivacyData/privacydata";
import PrivacyandTermsHeader from "../../components/PrivacyandTermsHeader/PrivacyandTermsHeader";
import GetStarted from "../../components/GetStarted/getstarted";

//define main component for privacypolicy page
//returns all the required components for the page
const privacypolicy = () =>{
    return(
        <>
            <PrivacyandTermsHeader headerText="Privacy Policy"/>
            <PrivacyData/>
            <GetStarted/>
        </>
    )
}
export default privacypolicy;//Export to call anywhere in the application