import React from 'react';
import './clientNdnews.css';
//Define functional component for ClientSection
//Passing images as Prop names images
//conditionally render the component based on the value of prop
//Returns the component by mapping images from 'images prop' and renders the UI
const ClientndNews = ({ images }) => {
  if (!images) {
    return null;
  }

  // CSS class to apply the black and white effect on the 2nd client image
  const blackAndWhiteImageStyle = {
    filter: "invert(1)",
  };

  return (
    <div className="client-section flex__center">
      {images.map((imageUrl, index) => (
        <div className="client-image" key={index}>
          <img
            src={imageUrl}
            alt={`client-${index}`}
            style={index === 1 ? blackAndWhiteImageStyle : {}}
            loading="lazy"
          />
        </div>
      ))}
    </div>
  );
}

export default ClientndNews;
