import React from 'react';
import './card.css';
import { Link } from 'react-router-dom';



const Card = ({data}) => {

  return (
    <div>
     {data.map((cardData, index) => (
  <div key={cardData.jobId || index} className='active-deal-card-container'>
          <div className="deal-card">
            
            {/* 1st row */}
            <div className="topbar-detail-box">
            <div className="active-deal-company-logo">
                <img src="https://develop.sharedpro.in/images/sharedpro_2.svg" alt="logo" />
              </div>
              <div className="deal-title">
                <h3>{cardData.jobSpecialization}</h3>
                <p>Dedicated Project</p>
              </div>
            </div>

            {/* 2nd row */}
            <div className="top-detail-box">
            <div className="detail-box">
            <span><b>Job Id: </b></span>
            <p className='job-id'>{cardData.jobId}</p>
            </div>
            <div className="detail-box">
            <span><b>Job-type:</b></span>
            <p className='job-type'>{cardData.jobType}</p>
            </div>
            <div className="detail-box">
            <span><b>location:</b></span>
            <p className='active-deal-location'>{cardData.jobLocation}</p>
            </div>
            <div className="detail-box">
            <span><b>Experience:</b></span>
            <p className='active-deal-exp'>{cardData.jobMinExperienceRequired}</p>
            </div>
              <div className="deal-buttons">
              <Link to={`/job-detail/${cardData.jobId}`}>
                <button className='deal-read-more'>Read More</button>
                </Link>
                <button className='deal-apply-now'>Share Talent</button>
              </div>
            </div>

            {/* 3rd row  */}
            <div className="bottom-detail-box">
            <div className="deals-skill">
            <p><b>Skills:</b></p>
            <div className="tag-container">
  {cardData.jobSkills && cardData.jobSkills.split(',').map((skill, index) => (
    <p key={index} className="active-deal-tag">{skill.trim()}</p>
  ))}
</div>

    </div>
            </div>
          </div>
          <br />
        </div>
      ))}
    </div>
  );
}

export default Card;
