import React from "react";
import "./Header.css";
import hero from "../../../assets/images/hero.webp";
import {FaArrowRight} from "react-icons/fa";


// The Header component represents the header/hero section of a web page.
const Header = () => (
    <div className="header">
        <div className="container">
            <div className="header__content text__center text__light flex flex__center">
                <div className="header__content--left">
                <div className="svg-background"></div>
                <img src={hero} alt="Transparent Photo" className="transparent-photo" loading="lazy" />
                </div>
                <div className="header__content--right">
                    <h1 className="header__title fw__6">We Design and Develop</h1>
                    <p className="para__text">We are a new design studio based in USA. We have over 20 years of combined experience, and know a thing or two about designing websites and mobile apps.</p>
       
    <a href="#" className="btn btn__blue rotate-on-hover">
        Get Started <FaArrowRight className="icon"/>
    </a>
    <a href="#" className="btn btn__blue get_started rotate-on-hover">
        Get Demo 
    </a>                    
                </div>
            </div>
        </div>
    </div>
)

export default Header;