import React, { useState, useEffect } from 'react';
import './pagination.css';
import {MdArrowForward, MdArrowBack} from 'react-icons/md'

const Pagination = ({ itemsPerPage, totalItems, onPageChange }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const pageRange = 2; // Number of visible page options
  const [visiblePages, setVisiblePages] = useState([1, Math.min(totalPages, pageRange)]);
  const [currentPage, setCurrentPage] = useState(1); // Define setCurrentPage here

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    onPageChange(pageNumber);
  };

  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    handlePageChange(pageNumber);
    window.scrollTo(0, 0);
  };

  const previousPage = () => {
    if (currentPage > 1) {
      goToPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      goToPage(currentPage + 1);
    }
  };

  const renderPaginationButtons = () => {
    const buttons = [];

    for (let i = visiblePages[0]; i <= visiblePages[1]; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => goToPage(i)}
          className={`pagination-button ${currentPage === i ? 'active-button' : ''}`}
        >
          {i}
        </button>
      );
    }

    return buttons;
  };

  const updateVisiblePages = () => {
    const currentPageIndex = currentPage - 1;
    const halfPageRange = Math.floor(pageRange / 2);
    let startPage = currentPage - halfPageRange;
    let endPage = currentPage + halfPageRange;

    if (startPage < 1) {
      startPage = 1;
      endPage = pageRange;
    }

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = totalPages - pageRange + 1;
    }

    setVisiblePages([startPage, endPage]);
  };

  useEffect(() => {
    updateVisiblePages();
  }, [currentPage, totalItems]);

  return (
    <div className="pagination">
      <button  className="pagination-button" onClick={previousPage} disabled={currentPage === 1}>
        <MdArrowBack />
      </button>
      {renderPaginationButtons()}
      <button className="pagination-button" onClick={nextPage} disabled={currentPage === totalPages}>
        <MdArrowForward/>
      </button>
    </div>
  );
};

export default Pagination;
