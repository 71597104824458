import React, { useEffect } from "react";
import './Footer.css';
import { FaEnvelope, FaLinkedin, FaInstagram, FaPhone, FaDiceOne } from 'react-icons/fa';
import sharedprowhite from '../../assets/images/sharedprowhitepng.png';
import iooxlogo from '../../assets/images/100xlogosvg.svg';
import iimalogo from '../../assets/images/iimalogo.png';
import { Link } from "react-router-dom";
//Define funtional component for footer 
//returns jsx structure to render UI
const Footer = () => {

    useEffect(() => {
      const links = document.querySelectorAll(".nav__ul a");
  
      links.forEach((link) => {
        link.addEventListener("click", () => {
          window.scrollTo(0, 0); // Scroll to the top of the page
        });
      });
    }, []);
  
  
  
  return (
    <>
      <footer className="footer">
        <div className="footer__top">
          {/* First Row */}
          <div className="footer__row">
            <div className="footer__subscribe">
              <img id="logo" src={sharedprowhite} alt="logo" loading="lazy" />
              <div className="contactus">
                <h2>Contact Us</h2>
                <ul>
                  <li>
                    <a href="mailto:example@gmail.com">
                    <FaEnvelope fontSize="large" className="icons" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                    <FaLinkedin fontSize="large" className="icons" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                    <FaInstagram fontSize="large" className="icons" />
                    </a>
                  </li>
                  <li>
                    <FaPhone fontSize="large" className="icons" id="phoneicon" />
                  </li>
                </ul>
              </div>
            </div>

            <ul className="footer__nav">
              <li className="nav__item">
                <h2 className="nav__title">MAIN</h2>
                <ul className="nav__ul">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/blog" target="_blank">Blog</Link>
                  </li>
                  <li>
                    <Link to="/case-study">Case Study</Link>
                  </li>
                  <li>
                    <a href="https://develop.sharedpro.in/sf3" target="_blank">Shared Future 3.0</a>
                  </li>
                </ul>
              </li>
              <li className="nav__item">
                <h2 className="nav__title">LEGAL</h2>
                <ul className="nav__ul">
                  <li>
                    <Link to='/termsncondi'>
                      Term of Use</Link>
                  </li>
                  <li>
                    <Link to="/privacypolicy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/informationsecurity">Information security policy</Link>
                  </li>
                  <li>
                    <a href="#">Version: Beta 2</a>
                  </li>
                </ul>
              </li>
            </ul>

            {/* "Backed By" Section */}
            <div className="backedby">
              <h2>Backed By</h2>
              <div className="backedby-logos">
                <div id="X-img"><a href="#"><img src={iooxlogo} alt="iooxlogo"  loading="lazy"/></a></div>
                <div id="iim-logo"><a href="#"><img src={iimalogo} alt="iimalogo"  loading="lazy"/></a></div>
              </div>
            </div>
          </div>
        </div>
        <hr id="line-above-copyright" />
        {/* Second Row */}
        <div className="footer__bottom">
          <div className="legal">
            <p>&copy; 2019 Something. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

