import React from "react";
// import getstartedimg from '../../assets/images/getStarted.svg';
import './getstarted.css';
import {FaArrowRight} from "react-icons/fa";
//define getStarted functional componennt
//return jsx to render UI

//This function is called in Information security, Privacy and policy and terms and conditions.
const getstarted = () => {
    return (
        <>
        <div className="getstartedContainer">
            <div className="leftText">
                <h4>GET IN TOUCH</h4>
                <h1>Ready To Share/Hire Talent For New Projects? </h1>
                <a href="#" className="btn btn__blue rotate-on-hover">
        Get Started <FaArrowRight className="icon"/>
    </a>
            </div>
            <div className="getstartedimage">
                {/* <img src={getstartedimg} alt="getStarted"  loading="lazy" /> */}
                <img src="https://html.hixstudio.net/seomy-prv/seomy/assets/img/bg/services-tab/value-tab-4.svg" alt="getStarted"  loading="lazy" />
            </div>
        </div>
        </>
    )
}
export default getstarted;//Export to use anywhere in the application