import React, { useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link ,useNavigate} from "react-router-dom";
import "./Navbar.css";
import logo from "../../assets/images/sharedpro_2.svg";
import { useLocation } from "react-router-dom";

//functional component for navbar
const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [isSticky, setIsSticky] = useState(true); 
  const location = useLocation();

  const closeMenu = () => {
    console.log("close");
    setToggleMenu(false);
  };

  const isHome = location.pathname === "/";

  const navbarClassName = `navbar ${isSticky ? "sticky" : ""} ${
    isHome ? "orange_color_nav" : "white_nav"
  }`;

  
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  }; 

  return (
    <nav className={navbarClassName}>
      <div className="container flex">
        <div className="brand__and__toggler flex">
       
          <a href="/" alt="logo" className="navbar__brand">
            <img src={logo} alt="site logo" 
             width="100" height="50"  loading="lazy"
             />
          </a>

          <button
            type="button"
            className="navbar__open--btn text__dark"
            onClick={() => setToggleMenu(true)}
          >
            <FaBars size={26} />
          </button>
        </div>

        <div className="navbar__collapse">
          <ul className="navbar__nav">
            <li className="nav__item">
              <Link
                to="/"
                className="nav__link text__upper fw__6 text__dark nav__active"
                // onClick={() => handleNavLinkClick("/")}
                onClick={scrollToTop}
              >
                home
              </Link>
            </li>
            <li className="nav__item">
              <Link
                to="/case-study"
                className="nav__link text__upper fw_6 text__dark"
                // onClick={() => handleNavLinkClick("/case-study")}
                onClick={scrollToTop}
              >
                Case Study
              </Link>
            </li>
            <li className="nav__item">
              <Link
                to="/blog"
                className="nav__link text__upper fw_6 text__dark"
                // onClick={() => handleNavLinkClick("/blog")}
                onClick={scrollToTop}
              >
                blog
              </Link>
            </li>
            <li className="nav__item">
              <Link
                to="/resources"
                className="nav__link text__upper fw_6 text__dark"
                // onClick={() => handleNavLinkClick("/resources")}
                onClick={scrollToTop}
              >
                Resources
              </Link>
            </li>
            <li className="nav__item">
              <Link
                to="/active-deals"
                className="nav__link text__upper fw_6 text__dark"
                // onClick={() => handleNavLinkClick("/active-deals")}
                onClick={scrollToTop}
              >
                Active Deals
              </Link>
            </li>
          </ul>
        </div>

        {toggleMenu && (
          <div className="navbar__smallscreen" >
            <button
              type="button"
              className="navbar__close--btn text__light"
              onClick={() => setToggleMenu(false)}
            >
              <FaTimes size={32} />
            </button>
            <ul className="navbar__nav--smallscreen text__light">
              <li className="nav__item">
                <Link
                  to="/"
                  className="nav__link text__upper fw__6 nav__active text__light"
                  onClick={closeMenu}
                >
                  home
                </Link>
              </li>
              <li className="nav__item">
                <Link
                  to="/case-study"
                  className="nav__link text__upper fw__6 text__light"
                  onClick={closeMenu}
                >
                  Case Study
                </Link>
              </li>
              <li className="nav__item">
                <Link
                  to="/blog"
                  className="nav__link text__upper fw__6 text__light"
                  onClick={closeMenu}
                >
                  blog
                </Link>
              </li>
              <li className="nav__item">
                <Link
                  to="/resources"
                  className="nav__link text__upper fw__6 text__light"
                  onClick={closeMenu}
                >
                  Resources
                </Link>
              </li>
              <li className="nav__item">
                <Link
                  to="/active-deals"
                  className="nav__link text__upper fw__6 text__light"
                  onClick={closeMenu}
                >
                  Active Deal
                </Link>
              </li>
            </ul>
            <a href="#" className="buttonNavbarRightAnchor">Get started</a>
          </div>
        )}

        <div className="buttonNavbarRight">
          <a href="#" className="buttonNavbarRightAnchor">Get started</a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
