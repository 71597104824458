import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

// Function to remove the splash screen
// function removeSplashScreen() {
//   const splashScreen = document.getElementById('splash-screen');
//   if (splashScreen) {
//       splashScreen.remove();
//       document.body.classList.add('ready');
//   }
// }

root.render(
  <React.StrictMode>
    {/* <App onAppReady={removeSplashScreen}/> */}
    <App />
  </React.StrictMode>
);

