import React from 'react';
import './callbackmodel.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// import axios from 'axios';

const CallBackModal = ({ isOpen, onClose }) => {
  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    phone: Yup.string().matches(/^\d{10}$/, 'Invalid phone number').required('Phone is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      phone: '',
    },
    validationSchema,
    onSubmit: (values) => {
    //   axios
    //     .post('/submit', values)
    //     .then((response) => {
    //       // Handle the response data (e.g., display it in the console)
    //       console.log(response.data);
    //     })
    //     .catch((error) => {
    //       // Handle any errors
    //       console.error(error);
    //     });
    console.log(values)

      // Close the modal
      onClose();
    },
  });

  return (
    <div className={`callback-modal ${isOpen ? 'active' : ''}`}>
      <div className="modal-content">
        <div className="modal-close" onClick={onClose}>
          &times;
        </div>
        <h2>Great we'll call you!</h2>
        <form onSubmit={formik.handleSubmit}>
          <div className={`input-container ${formik.touched.email && formik.errors.email ? 'focused' : ''}`}>
            <input
              type="email"
              id="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
            <label htmlFor="email">Enter your working email address</label>
          </div>
          {formik.touched.email && formik.errors.email && (
            <div className="error-message">{formik.errors.email}</div>
          )}
          <div className={`input-container ${formik.touched.phone && formik.errors.phone ? 'focused' : ''}`}>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
            <label htmlFor="phone">Enter your phone number</label>
          </div>
          {formik.touched.phone && formik.errors.phone && (
            <div className="error-message">{formik.errors.phone}</div>
          )}
          <button className="callBackButton" type="submit">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default CallBackModal;
