import React from "react";
import './PrivacyandTermsHeader.css';

//define privacyandtermsheader component used in privacy and terms page
//returns  jsx to render UI
//accepts Title as a prop
const privacyandtermsheader=(props)=>{
    return(
        <div className="privacyandtermsbanner">
        <h2 className="headerText">{props.headerText}</h2>
    </div>
    )
    
}
export default privacyandtermsheader;