import React, { useEffect } from "react";
import './privacydata.css';
import PrivacyandTermsCard from "../../../components/PrivacyandTermsCard/PrivacyandTermsCard";

//define privacydata component for privacyPolicyPage
//returns jsx to render UI and calls comman component PrivacyTermsCard
const Privacydata = () => {
        // to set the title of the page
        useEffect(()=>{
            document.title = 'Privacy & Policy |Sharedpro';
         },[])
    return (
        <>
            <div className="cardntext">
                <div>
                <PrivacyandTermsCard />
                </div>
                
                <div className="privacydata">
                    <div className="introduction">
                        <h3 className="policyAgreement">Privacy Policy Agreement</h3>
                        <p>We, at sharedpro, are committed to safeguarding your online privacy and recognize your need for appropriate protection and management of any personally identifiable information ("Personal Information") you share with us. In order to provide a personalised browsing experience, sharedpro may collect information from you, this may include technical or other related information from the device used to access sharedpro including without limitation contact lists in your device and its location, you may also be asked to complete a registration form. When you let us have your preferences, we will be able to deliver or allow you to access the most relevant information that meets your needs.
                        </p>
                        <p>"Personal Information" means any information that may be used to identify an individual, including, but not limited to, a first and last name, a home or other physical address and an email address or other contact information, whether at work or at home. In general, you can visit sharedpro website or mobile application without telling us who you are or revealing any personal information about yourself.</p>
                    </div>
                    <div className="consent">
                        <h3 className="yourconsent">Your Consent</h3>
                        <p>By using this website, you consent to the terms of our online privacy policy and to sharedpro's processing of personal information for the purposes given below as well as those explained where sharedpro collects personal information on the web.
                        </p>
                    </div>
                    <div className="info">
                        <h3 className="infowecollect">Information we collect</h3>
                        <ul>
                            <li>
                                <div className="infoList">
                                    <h5>Registration:</h5>
                                    <p>To create an account you need to provide data including your name, email address and/or mobile number, and a password.</p>
                                </div>
                            </li>
                            <li><div className="infoList">
                                <h5>Contact Information:</h5>
                                <p>We might collect your name, email, mobile number, phone number, street, city, state, pin code, country and IP address.</p>
                            </div></li>
                            <li><div className="infoList">
                                <h5>Information you post:</h5>
                                <p>We collect information you post in a public space on our website or on a third-party social media site associated with Sharedpro.</p>
                            </div></li>
                            <li><div className="infoList">
                                <h5>We collect information from you passively:</h5>
                                <p> We may use tracking tools like google analytics, google webmaster, browser cookies and web beacons for collecting information about your usage of our website.</p>
                            </div></li>
                        </ul>
                    </div>
                    <div className="infoshare">
                        <h3 className="inforshareheader"> Information we share</h3>
                        <ul>
                            <li><div >
                                <h5>Third Party Access:</h5>
                                <p>Sharedpro does not sell, rent or share your personal data with other people or companies without your consent except to provide products or services that you requested.When we have your permission, or under the following circumstances:</p>
                                <div className="infoShareList">
                                    <ul>
                                        <li>We provide the information to trusted partners who work on behalf of or with sharedpro under confidentiality agreements. These companies may use your personal information to help sharedpro communicate with you about offers from sharedpro and our marketing partners. However, these companies do not have any independent right to share this information</li>
                                        <li>We respond to subpoenas, court orders, or legal process, or to establish or exercise our legal rights or defend against legal claimsWe transfer information about you if sharedpro is acquired by or merged with another company. In this event, sharedpro will notify you before information about you is transferred and becomes subject to a different privacy policy.</li>
                                        <li>We transfer information about you if sharedpro is acquired by or merged with another company. In this event, sharedpro will notify you before information about you is transferred and becomes subject to a different privacy policy.</li>
                                    </ul>
                                </div>
                            </div></li>
                            <li>
                                <div>
                                    <h5>Security</h5>
                                    <p>The security of your personal information is important to us, but remember that no method of transmission over the internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="acceptance">
                        <h3 className="accpheader">Your acceptance of these terms</h3>
                        <p>By using this site, you signify your acceptance of this policy. If you do not agree to this policy, please do not use our Site. Your continued use of the site following the posting of changes to this policy will be deemed your acceptance of those changes</p>
                        <ul><li><h5>Jurisdiction</h5>
                            <p>The courts of vadodara will have exclusive jurisdiction to adjudicate any dispute arising under or in connection with this privacy policy and will be governed by laws in india (Indian Laws).</p></li></ul>
                    </div>
                    <div className="changepolicy">
                        <h3 className="changepolicyheader">Changes to this privacy policy</h3>
                        <p>Sharedpro reserves the right to update, change or modify this policy at any time. The policy shall come to effect from the date of such update, change or modification. We'll notify by placing a prominent notice on our website or by email.</p>
                    </div>
                    <div className="contactinfo">
                        <h3 className="contactinfoheader">
                            Contact information</h3>
                        <p>Sharedpro welcomes your comments regarding this privacy statement at the contact address given at the website. Should there be any concerns about contravention of this privacy policy, sharedpro will employ all commercially reasonable efforts to address the same.</p>
                    </div>
                </div>
            </div>

        </>
    )
}
export default Privacydata;//Export component to call anywhere in application