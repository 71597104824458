// Card.js
import React from 'react';
import './card.css';


// card component for whyUs section in Home page.
const Card = ({ title, description, imageUrl }) => {
  return (
    <div className='card flex__center'>
      <div className='card-image'>
        <img src={imageUrl} alt={title} loading="lazy" />
      </div>
      <div className='card-content'>
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default Card;
