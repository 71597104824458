import React, { useEffect } from 'react'
import './caseStudy.css'
import Card from './Card/Card'
import ClientndNews from '../../components/ClientndNews/clientNdnews';
import logo1 from '../../assets/images/smartcloud-logo.svg';
import logo2 from '../../assets/images/orr2.svg';
import logo3 from '../../assets/images/HappiestMinds_Logo.svg';
import logo4 from '../../assets/images/gignex.svg';
import logo5 from '../../assets/images/int.svg';

//Define functional component for CaseStudy
//Calls all the component for caseStudy page with props
//Contians clientImage array for 'images' prop in Client Component
function CaseStudy() {
  const ClientndNewsImages = [
        logo1,
        logo2,
        logo3,
        logo4,
        logo5,
  ];


  useEffect(()=>{
    document.title = 'Case Study|Sharedpro';
  },[])
  return (
    <div className="case-study-container">
      <div className="case-study-header flex__center">
        <div className="case-study-title">
          <h1>Case Study</h1>
        </div>
      </div>
      <Card />
      <ClientndNews images={ClientndNewsImages } />
    </div>

  )
}

export default CaseStudy