import React from "react";
import './termsdata.css';
import PrivacyandTermsCard from "../../../components/PrivacyandTermsCard/PrivacyandTermsCard";


//define privacydata component for privacyPolicyPage
//returns jsx to render UI and calls comman component PrivacyTermsCard
const termsdata = () => {
    return (
        <>
            <div className="cardntext">
                <PrivacyandTermsCard />
                <div className="termstext">
                    <div className="termsheader" >
                        <h3>1.Introduction</h3>
                    </div>
                    <div className="contract">
                        <h4>Contract</h4>
                        <p>You agree that by clicking “join now”, “join sharedpro”, “sign up” or similar registering, accessing or using our services (described below), you are agreeing to enter into a legally binding contract with sharedpro (even if you are using our services on behalf of a company). If you do not agree to this contract (“contract” or “user agreement”), do not click “join now” (or similar) and do not access or use any of our services. If you wish to terminate this contract, at any time you can do so by closing your account and no longer accessing or using our services.</p>
                    </div>
                    <div className="services">
                        <h4>Services</h4>
                        <p>This contract applies to sharedpro.in and sharedpro-branded apps. Registered users of our services are “members” and unregistered users are “visitors”. This contract applies to both members and visitors.

                            As a visitor or member of our services, the collection, use and sharing of your personal data is subject to this privacy policy (which includes our cookie policy and other documents referenced in this privacy policy) and updates.</p>
                    </div>
                    <div className="members">
                        <h4>Members and Visitors</h4>
                        <p>When you register and join the sharedpro service, you become a member. If you have chosen not to register for our services, you may access certain features as a visitor.</p>
                        <ul>
                            <li>
                                <div>
                                    <h4>Members</h4>
                                    <ul>
                                        <li>
                                            <div>
                                                <h5>
                                                    Employers</h5>
                                                <ul><li>Employing company: companies who’ve listed their employees for sharing on sharedpro.</li>
                                                    <li>Hiring company: companies who've posted a job and are hiring an employee on sharedpro.</li></ul></div>
                                        </li>
                                        <li>
                                            <div>
                                                <h5>
                                                    Professionals</h5>
                                                <ul><li>Shared employees: employees listed for sharing on sharedpro, they always have an employing company handling their payroll.</li>
                                                    <li>Job seekers: candidates without any employing company registered on sharedpro.</li></ul></div>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="Change">
                        <h4>Change</h4>
                        <p>We may modify this contract, our privacy policy and our cookies policies from time to time. If we make material changes to it, we will provide you notice through our services, or by other means, to provide you the opportunity to review the changes before they become effective. We agree that changes cannot be retroactive. If you object to any changes, you may close your account.</p>
                    </div>
                    <div className="obligations" >
                        <h3>2.Obligations</h3>
                    </div>
                    <div className="service-elig">
                        <h4>Service Eligibility</h4>
                        <p>The services are not for use by anyone under the age of 18.</p>
                        <p>To use the services, you agree that:</p>
                        <ul>
                            <li>You must be the “minimum age” (described below) or older;</li>
                            <li>You will only have one sharedpro account, which must be in your real name;</li>
                            <li>You are not already restricted by sharedpro from using the services. Creating an account with false information is a violation of our terms, including accounts registered on behalf of others or persons under the age of 18.</li>
                        </ul>
                        <p>You are responsible for anything that happens through your account unless you close it or report misuse.</p>
                        <p>As between you and others (including your employer), your account belongs to you. However, if the services were purchased by another party for you to use, the party paying for such service has the right to control access to and get reports on your use of such paid service; however, they do not have rights to your personal account.</p>
                    </div>
                    <div className="youracc">
                        <h4>Your Account</h4>
                        <p>Members are account holders. You agree to</p>
                        <ul>
                            <li>Try to choose a strong and secure password</li>
                            <li>Keep your password secure and confidential and</li>
                            <li>Not transfer any part of your account (e.g ., connections)</li>
                        </ul>
                        <p>You are responsible for anything that happens through your account unless you close it or report misuse.</p>
                        <p>As between you and others (including your employer), your account belongs to you. However, if the services were purchased by another party for you to use, the party paying for such service has the right to control access to and get reports on your use of such paid service; however, they do not have rights to your personal account</p>
                    </div>
                    <div className="payment">
                        <h4>Payment</h4>
                        <p>If you buy any of our paid services, you agree to pay us the applicable fees and taxes and additional terms specific to the paid services. Failure to pay these fees will result in the termination of your paid services with penalty. Also, you agree that</p>
                        <ul>
                            <li>We may store and continue billing your payment method (e.g. credit card) even after it has expired, to avoid interruptions in your services and to use to pay other services you may buy.</li>
                            <li>For hiring employers, any kind of payment to the shared employee like salary, allowance or other expenses if any should only be paid to the employing company (to ensure a unified single salary payment to the employee). The hiring employer should refrain itself from any direct transaction to the employee.</li>
                            <li>The hiring employer should pay for its part of the compensation of the shared employee within the first 10 days of every next month. If for any reasons the hiring employer fails to pay within the stipulated time then the employer should convey the same to the employing company or sharedpro.</li>
                            <li>All of your purchases of services are subject to sharedpro’s refund policy.</li>
                            <li>We may calculate taxes payable by you based on the billing information that you provide us at the time of purchase.</li>
                        </ul>
                        <p>You are responsible for anything that happens through your account unless you close it or report misuse.</p>
                        <p>As between you and others (including your employer), your account belongs to you. However, if the services were purchased by another party for you to use, the party paying for such service has the right to control access to and get reports on your use of such paid service; however, they do not have rights to your personal account.</p>
                    </div>
                    <div className="deferredhiring">
                        <h4>Deferred Hiring</h4>
                        <p>The payment would be due and payable if a candidate referred by sharedpro is offered employment by the hiring company within 6 months of such referral and joins the hiring company. If the same candidate is received through another source after the expiry of 6 months period and is selected and joins the hiring company, then the credit will go to the other source</p>
                    </div>
                    <div className="nopoaching">
                        <h4>No Poaching</h4>
                        <p>Hiring companies cannot contact the employees listed on sharedpro for any other purpose other than the service offered by the employing company. If the employing company has listed its employees for sharing on a fixed predefined tenure, then the hiring company cannot contact and/or offer the employees any permanent roles in either their company or any other company without a written consent of the employing company. Hiring an employee listed for sharing either directly or indirectly for a permanent position without any consent of the employing company may fall within the purview of section 3 of the competition act and hence it is strictly prohibited.</p>
                    </div>
                    <div className="notice">
                        <h4>Notices and Messages</h4>
                        <p>You agree that we will provide notices and messages to you in the following ways</p>
                        <ul>
                            <li>Within the Service,</li>
                            <li>Sent to the contact information you provided us (e.g, email, mobile number, physical address). You agree to keep your contact information up to date</li>

                        </ul>

                    </div>
                    <div className="sharing">
                        <h4>Sharing</h4>
                        <p>Our services allow sharing of information in many ways, such as your profile, links to news articles, job postings, and blogs. Information and content that you share or post may be seen by other members, visitors or others. Wherever we have made settings available, we will honor the choices you make about who can see content or information (e.g., sharing content only to sharedpro connections, restricting your profile visibility from search engines, or opting not to notify others of your sharedpro profile update). For job searching activities, we would by default not notify your connection network or the public So if you apply for a job through our service or opt to signal that you are interested in a job, our by default is to share it only with the job poster.</p>
                    </div>
                    <div className="rights" >
                        <h3>3.Rights and Limits</h3>
                    </div>
                    <div className="licensetosharedpro">
                        <h4>Your license to Sharedpro</h4>
                        <p>As between you and sharedpro, you own the content and information that you submit or post to the services, and you are only granting sharedpro and our affiliates the following non-exclusive license
                        </p>
                        <p>A worldwide, transferable and sub-licensable. right to use, copy, modify, distribute, publish, and process, information and content that you provide through our services and the services of others, without any further consent, notice and/or compensation to you or others. These rights are limited in the following ways:</p>
                        <ul><li>You can end this license for specific content by deleting such content from the services, or generally by closing your account, except (a) to the extent you shared it with others as part of the service and they copied, re-shared it or stored it and (b) for the reasonable time it takes to remove from backup and other systems.</li>
                            <li>We will not include your content in advertisements for the products and services of third parties to others without your separate consent (including sponsored content). However, we have the right, without payment to you or others, to serve ads near your content and information, and your social actions may be visible and included with ads, as noted in the privacy policy
                            </li>
                            <li>We will take your consent if we want to give others the right to publish your content beyond the services.</li>
                            <li>While we may edit and make format changes to your content (such as translating it, modifying the size, layout or file type or removing metadata), we will not modify the meaning of your expression.</li>
                            <li>While we may edit and make format changes to your content (such as translating it, modifying the size, layout or file type or removing metadata), we will not modify the meaning of your expression.</li></ul>
                        <p>You and sharedpro agree that we may access, store, process and use any information and personal data that you provide in accordance with the terms of the privacy policy and your choices (including settings).</p>
                        <p>By submitting suggestions or other feedback regarding our services to sharedpro, you agree that sharedpro can use and share (but does not have to) such feedback for any purpose without compensation to you.</p>
                        <p>You agree to only provide content or information that does not violate the law and anyone's right (including intellectual property rights). You also agree that your profile information will be truthful. Sharedpro may be required by law to remove certain information or content in certain country</p>
                    </div>
                    <div className="serviceavail">
                        <h4>Service Availability</h4>
                        <p>We may change or discontinue any of our services. We don’t promise to store or keep showing any information and content that you’ve posted.</p>
                        <p>Sharedpro is not a storage service. You agree that we have no obligation to store, maintain or provide you a copy of any content or information that you or others provide, except to the extent required by applicable law and as noted in our Privacy Policy</p>
                    </div>
                    <div className="othercontent">
                        <h4>Other Content, Sites and Apps</h4>
                        <p>By using the services, you may encounter content or information that might be inaccurate, incomplete, delayed, misleading, illegal, offensive or otherwise harmful. sharedpro generally does not review the content provided by our members or others. You agree that we are not responsible for others’ (including other Members’) content or information. We cannot always prevent this misuse of our services, and you agree that we are not responsible for any such misuse.</p>
                        <p>You are responsible for deciding if you want to access or use third-party apps or sites that link from our services. If you allow a third-party app or site to authenticate you or connect with your sharedpro account, that app or site can access information on sharedpro related to you and your connections. Third-party apps and sites have their own legal terms and privacy policies, and you may be giving others permission to use your information in ways we would not. Except to the limited extent, it may be required by applicable law, sharedpro is not responsible for these other sites and apps ‐ use these at your own risk. Please see our privacy policy.</p>
                    </div>
                    <div className="limit">
                        <h4>Limits</h4>
                        <p>We have the right to limit how you connect and interact on our services</p>
                        <p>Sharedpro reserves the right to limit your use of the services, including the number of your connections and your ability to contact other Members. Sharedpro reserves the right to restrict, suspend, or terminate your account if sharedpro believes that you may be in breach of this contract or law or are misusing the services (e.g., violating any of the dos and don’ts or professional community policies).</p>
                    </div>
                    <div className="intellectualproperty">
                        <h4>Intellectual Property Right</h4>
                        <p>Sharedpro reserves all of its intellectual property rights in the services. Using the services does not give you any ownership in our services or the content or information made available through our services. Trademarks and logos used in connection with the services are the trademarks of their respective owners. Sharedpro trademarks, service marks, graphics, and logos used for our services are trademarks or registered trademarks of sharedpro.</p>

                    </div>
                    <div className="automatedprocessing">
                        <h4>Automated Processing</h4>
                        <p>We have the right to limit how you connect and interact on our services</p>
                        <p>We will use the information and data that you provide and that we have about members to make recommendations for content and features that may be useful to you. For example, we use data and information about you to recommend jobs to you and you to recruiters. Keeping your profile accurate and up-to-date helps us to make these recommendations more accurate and relevant.</p>
                    </div>
                    <div className="disclaimer" >
                        <h3>4.Disclaimer and Limit of Liability</h3>
                    </div>
                    <div className="nowarranty">
                        <h4>No Warranty</h4>
                        <p>You agree that by clicking “join now”, “join sharedpro”, “sign up” or similar registering, accessing or using our services (described below), you are agreeing to enter into a legally binding contract with sharedpro (even if you are using our services on behalf of a company). If you do not agree to this contract (“contract” or “user agreement”), do not click “join now” (or similar) and do not access or otherwise use any of our services. If you wish to terminate this contract, at any time you can do so by closing your account and no longer accessing or using our services.</p>
                    </div>
                    <div className="exclusionogliability">
                        <h4>Exclusion Of Liability</h4>
                        <p>To the extent permitted under law (and unless sharedpro has entered into a separate written agreement that overrides this contract), sharedpro and its affiliates (and those that sharedpro works with to provide the services) shall not be liable to you or others for any indirect, incidental, special, consequential, or punitive damages, or any loss of data, opportunities, reputation, profits, or revenues, related to the services (e.g. offensive or defamatory statements, downtime or loss, use of, or changes to, your information or content).</p>
                        <p>In no event shall the liability of sharedpro and its affiliates (and those that sharedpro works with to provide the services) exceed, in the aggregate for all claims, an amount that is the lesser of (a) five times the most recent monthly or yearly fee that you paid for a premium service, if any, or (b) INR 10000.</p>
                        <p>This limitation of liability is part of the basis of the bargain between you and sharedpro and shall apply to all claims of liability (e.g. warranty, tort, negligence, contract, law) and even if sharedpro or its affiliates has been told of the possibility of any such damage, and even if these remedies fail their essential purpose.</p>
                    </div>
                    <div className="termination">
                        <h4>5.Termination</h4>
                        <p>Both you and sharedpro may terminate this contract at any time with notice to the other. On termination, you lose the right to access or use the services. The following shall survive termination:</p>
                        <ul><li>Our rights to use and disclose your feedback</li>
                            <li>Members and/or visitors’ rights to further re-share content and information you shared through the service to the extent copied or re-shared prior to termination</li>
                            <li>Sections 4, 6, 7, and 8.2 of this contract</li>
                            <li>Any amounts owed by either party prior to termination remain owed after termination.</li></ul>
                    </div>
                    <div className="governinglaw">
                        <h4>6.Governing law and dispute resolution</h4>
                        <p>You and sharedpro agree that the laws of India, excluding its conflict of laws rules, shall exclusively govern any dispute relating to this contract and/or the services. You and sharedpro both agree that all claims and disputes can be litigated only in the state courts in India and you and sharedpro each agree to personal jurisdiction in those courts.</p> 
                    </div>
                    <div className="generalterms">
                        <h4>7.General Terms</h4>
                        <p>If a court with authority over this contract finds any part of it unenforceable, you and we agree that the court should modify the terms to make that part enforceable while still achieving its intent. If the court cannot do that, you and we agree to ask the court to remove that unenforceable part and still enforce the rest of this contract.</p> 
                        <p>To the extent allowed by law, the english language version of this contract is binding and other translations are for convenience only. This contract (including additional terms that may be provided by us when you engage with a feature of the services) is the only agreement between us regarding the services and supersedes all prior agreements for the services.</p>
                        <p>If we don't act to enforce a breach of this contract, that does not mean that sharedpro has waived its right to enforce this contract. You may not assign or transfer this contract (or your membership or use of services) to anyone without our consent. However, you agree that sharedpro may assign this contract to its affiliates or a party that buys it without your consent. There are no third-party beneficiaries to this contract.</p>
                        <p>You agree that the only way to provide us with legal notice is at the addresses provided.</p>
                    </div>
                    <div className="dosnddont">
                        <h4>8.Do's</h4>
                        <p>Sharedpro is an employee sharing platform. This list of dos and don'ts limit what you can and cannot do on our services.</p>
                        <p>You agree that you will:</p>
                        <ul>
                            <li>Comply with all applicable laws, including, without limitation, privacy laws, intellectual property laws, anti-spam laws, export control laws, tax laws, and regulatory requirements</li>
                            <li>Provide accurate information to us and keep it updated</li>
                            <li>Use your real name on your profile and</li>
                            <li>Use the services in a professional manner.</li>
                        </ul>
                    </div>
                </div>
            </div>

        </>
    )
}
export default termsdata;//Export to anywhere in the application
