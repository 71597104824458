import React, { useEffect, useState } from 'react'
import './JobDetail.css';
import { AiFillLinkedin,AiOutlineGoogle} from "react-icons/ai";
import { BsTwitter} from "react-icons/bs";
import { FaExternalLinkAlt} from "react-icons/fa";
import { RiUserSharedFill} from "react-icons/ri";
import { MdWifiCalling3} from "react-icons/md";
import CallBackModal from './CallBackModal';
import jobdetailHeader from  '../../../assets/images/jobdetailHeader.jpg'
import axios from 'axios';
import { useParams } from 'react-router-dom';



const JobDetail = () => {

    // to set the title of the page
    useEffect(()=>{
        document.title = 'Job details|Sharedpro';
    },[])

    const { jobId } = useParams();

  // to set the data during api call  
    const [data, setData] = useState([]);
   
    const apiUrl = `https://develop.sharedpro.in/getJobDetails/${jobId}`;
    // const apiUrl = `https://develop.sharedpro.in/getJobDetails/154717`;

  useEffect(()=>{
    axios.get(apiUrl)
    .then(response => {
      console.log('API Response:', response.data.data);
      setData(response.data.data)
    })
    .catch(error => {
      console.error('Error:', error);
    });

  },[])

   // Split the primarySkills string and render the skill tags
    // const primarySkillsArray = response.data.primarySkills.split(',').map(skill => skill.trim());
    // const primarySkillsArray = data.primarySkills.split(',').map(skill => skill.trim());
    const primarySkillsArray = data.primarySkills ? data.primarySkills.split(',').map(skill => skill.trim()) : [];



    // Split the primarySkills string and render the skill tags
    // const secondarySkillsArray = response.data.secondarySkills.split(',').map(skill => skill.trim());
    // const secondarySkillsArray = data.secondarySkills.split(',').map(skill => skill.trim());
    const secondarySkillsArray = data.secondarySkills ? data.secondarySkills.split(',').map(skill => skill.trim()) : [];



    // to open close the callback model
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };

  return (
    <div className='job-detail-container'>
    <div className="jd-container">
    <br />

    {/* header section  starts*/}
    <div className="job-detail-header">
            <div className="header-image-container">


                {/* <img src="https://sharedpro.in/images/jobdetailHeader.jpg" alt="job-detail-header" /> */}
                <img src={jobdetailHeader} alt="job-detail-header" />
                <div className="contactNumber">
                    <span className="contactNotext">
                        Contact Us:
                    </span>
                    <div className="SocialMediaIcontag" title="Email">
                        <AiOutlineGoogle />
                    </div>
                    <div className="SocialMediaIcontag" title="twitter">
                        <BsTwitter />
                    </div>
                    <div className="SocialMediaIcontag" title="Linkedln">
                        <AiFillLinkedin />
                    </div>
                </div>
            </div>
            <div className="jd-header-body-container">
                <div className="jd-specialization-content">
                <div className="jd-specializationImage">
                <svg className="JobSpecializationLogo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M184 48H328c4.4 0 8 3.6 8 8V96H176V56c0-4.4 3.6-8 8-8zm-56 8V96H64C28.7 96 0 124.7 0 160v96H192 320 512V160c0-35.3-28.7-64-64-64H384V56c0-30.9-25.1-56-56-56H184c-30.9 0-56 25.1-56 56zM512 288H320v32c0 17.7-14.3 32-32 32H224c-17.7 0-32-14.3-32-32V288H0V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V288z"></path></svg>
                </div>
                <h1 className='jobSpecializationText'>
                    {/* {response.data.jobSpecialization} */}
                    {data.jobSpecialization}
                </h1>
                <p className="jobSpecializationPara">Dedicated Project</p>
                </div>
                <div className="jd-header-body-right-content">
                    <span className="jd-share-talent-icon" title="Share Talent">
                    <RiUserSharedFill />
                    </span>
                    <span className="jd-call-icon" title="Request call back" onClick={openModal}>
                        <MdWifiCalling3 />
                    </span>
                    <CallBackModal isOpen={isModalOpen} onClose={closeModal} />
                </div>
            </div>
        </div>
    {/* header section  ends*/}


        {/* job-detail content container starts*/}
        <div className="job-content-container">

            {/* left container starts*/}
            <div className="job-description-left-container">
                <p className='jd-title'><b>Job Description:</b></p>
            {/* <p dangerouslySetInnerHTML={{ __html: response.data.jobDescription }}></p> */}
            <p dangerouslySetInnerHTML={{ __html: data.jobDescription }}></p>
            </div>
            {/* left container ends*/}

            {/* right container starts  */}
            <div className="job-description-right-container">
             
          

                <div className="experience-block">
                    <p className="minexp">Experience(Min):</p>
                    <p className="exp-duration-min">
                        {data.minExperienceRequired} years
                        </p>
                </div>
                <div className="experience-block">
                    <p className="minexp">Experience(Max):</p>
                    <p className="exp-duration">
                        {data.maxExperienceRequired} years
                        </p>
                </div>
                <div className="job-type">
                    <p className="job-type-title">Job Type:  </p>
                    <p className="job-type-detail">
                    {data.jobType}
                    </p>
                </div>
                <div className="jd-location">
                    <p className="location-title">Location: </p>
                    <p className="jd-location-detail">
                    {data.jobLocation}
                    </p>
                </div>
                <div className="jd-primary-skills-section">
                <p className="jd-primary-skill">Primary Skills:</p>

                {primarySkillsArray.map((skill, index) => (
                    <span key={index} className='skill-tag'>
                    {skill}
                    </span>
                ))}
                </div>
                <div className="jd-secondary-skills">
                <p className="jd-secondary-skill">Secondary Skills:</p>
                {secondarySkillsArray.map((skill, index) => (
                    <span key={index} className='skill-tag'>
                    {skill}
                    </span>
                ))}

                </div>
            </div>
            {/* right container ends */}
        </div>
        {/* job-detail content container starts*/}


    {/* refer and earn section  starts*/}
    <div className="jd-refer-and-earn">
        <div className="refer-earn-title">
            <h1>Refer and Earn</h1>
            <span>Learn more <FaExternalLinkAlt /></span>
        </div>
        <div className="jd-create-link">
            <input type="text" placeholder='Enter your Email Address' />
            <button className='create-link-btn'>Create Link</button>
        </div>
    </div>
    {/* refer and earn section  ends*/}
    </div>
<br />
    </div>
  )
}

export default JobDetail