import React from 'react';
import './readmore.css';
import { FaArrowRight } from "react-icons/fa"
// import { useEffect } from 'react';


//Define functional component for readmore button in caseStudy
//return jsx for UI render
//Contains data array for info-card data mapping
const readmore = () => {
    //  // to set the title of the page
    //  useEffect(()=>{
    //   document.title = "Indus Net Technologies gets talent deployed from next day|Sharedpro"
    // },[])

  const data = [
    {
      clientName: 'Avg Duration of Projects:',
      infoCol2Data: '15 Months',
    },
    {
      clientName: 'Active Talent:',
      infoCol2Data: '2 Active Talent',
    },
    {
      clientName: 'Select to Hire ration:',
      infoCol2Data: '100%',
    },
  ];

  return (
    <>
      <div className="readmore-container">
        <div className="readmore-header">
          <div className='header-col1'>
            <img src="https://www.indusnet.co.in/site/wp-content/themes/indusnetblog/assets/images/logo-inverse.svg" alt="" id="readme-img" />
            <div className='header-text'>
              <h2>
              iCliQ Solution gets their candidates deployed on long-term projects with Sharedpro
              </h2>
              <p>iCliQ Solution, an Indian outsourcing company providing IT solutions and services, aimed to secure long-term enterprise projects as dealing with smaller projects often introduced a high degree of uncertainty. With Sharedpro, they got access to projects offered by major corporations.</p>
            </div>
          </div>
          <div className='header-col2'>
          </div>
        </div>
        <div className="info-section">
          <div className='info-img'>
            <img src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2084&q=80" alt="" />
          </div>
          <div className='info-card'>
            {data.map((item, index) => (
              <div key={index} className='infocard-col1'>
                <div className='infocard-col2'>
                  <p> <FaArrowRight className='arrow-icon' /> {item.clientName}</p>
                </div>
                <p style={{ borderBottom: '1px solid #999' }}>{item.infoCol2Data}</p>
              </div>
            ))}
          </div>

        </div>

        <div className="content-challenge">
          <div className="card-textcontainer">
            <div className='challenge-text'>
              <h2>01. The Challenge</h2>
              <p>iCliQ Solution has been proving its prowess in the IT industry by completing projects for companies of different sizes. Still the biggest challenge for them has been the small tenure of projects which effectively means their sales team has to keep looking for new projects round the clock.</p>
              <p>Add to this the black swan events like Covid-19 when new projects could either completely dry up or there is just too much competition to acquire new projects. According to Sankit Koladiya Owner of Icliq Solutions, “Retaining client for a long period of time is a big task. In today’s time, it is very uncertain whether the candidate will be retained by the client after 6 months. It’s always a challenge to find the next project for the candidate before he goes to bench”.</p>
              <p>For any business, having long term projects ensures continual stream of revenues. iCliQ Solution was looking for a solution to cater to this challenge and Sharedpro turned out to be the best bet for them.</p>
            </div>
            <div className='card-container'>
              <img src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2080&q=80" alt="" />
              <h4 className='card-title'>About iCliQ Solution</h4>
              <p>This Indian outsourcing company is based in Ahmedabad, India. They are a team of committed, dedicated, motivated, highly skilled, and always passionate to provide the best IT solution and services.
              </p>
              <ul>
                <li>
                  <div className='about-list1'>
                    <h4>Industry</h4>
                    <p>Outshoring/Offsoring</p>
                  </div>
                </li>
                <li><div className='about-list'>
                  <h4>Company Size</h4>
                  <p>11-50 employees</p>
                </div></li>
                <li><div className='about-list3'>
                  <h4>Headquaters</h4>
                  <p>Ahemdabad/India</p>
                </div></li>
              </ul>
            </div>
          </div>
          <div className='challenge-card'>
            <div>
              <div className="challenge-img">
                <div className='image1'>
                  <img src="https://plus.unsplash.com/premium_photo-1661375427386-64ac35086427?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80" alt="" />
                </div>
                <div className='image2'>
                  <img src="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80" alt="" />
                </div>
              </div>
              <div className='process-text'>
                <h2>02. Our Process</h2>
                <p>iCliQ Solution collaborated with Sharedpro to find long term IT projects for their developers.Sharedpro helped iCliQ Services in mainly three ways:</p>
                <ul className='process-list'>
                  <li>
                    <div className="list-title">
                      <img src="https://demo.egenslab.com/html/corelaw/preview/assets/images/icons/list-arrow.svg" alt="" width={'40px'} height={'40px'} />
                      <h3>Long term projects</h3>
                    </div>
                    <p>Client retention was the major issue faced by iCliQ Solution, continuous flow of work was very important attribute for them. It is difficult to sustain if 4-5 candidates are without work, due to lack of and extension of projects as then that could be a very troublesome situation.</p>
                    <p>Sharedpro helped iCliQ Solution in providing some very reliable clients on whom they could rely for long-term workflow for a couple of years.</p>
                  </li>
                  <li>
                    <div className="list-title">
                      <img src="https://demo.egenslab.com/html/corelaw/preview/assets/images/icons/list-arrow.svg" alt="" width={'40px'} height={'40px'} />
                      <h3>Working with big brands</h3>
                    </div>
                    <p>Branding paves the way in getting high value projects from large enterprises. iCliQ Services was facing challenges to establish any direct connection with large clients making it difficult to build their own brand. iCliQ Services was proactively looking for a way to work with big brands ultimately helping them and their employees get exposure to challenging work environments.</p>
                    <p>Sharedpro provided them a platform to not only work with large enterprises like Tesco but also managing end to end operations with complete peace of mind.</p>
                  </li>
                  <li>
                    <div className="list-title">
                      <img src="https://demo.egenslab.com/html/corelaw/preview/assets/images/icons/list-arrow.svg" alt="" width={'40px'} height={'40px'} />
                      <h3>Establishing relationship and trust with clients</h3>
                    </div>
                    <p>Establishing trust with the clients is very important, relationships with the clients should not get hampered so that the association with them could last longer. Building trust at times gets difficult due to some reason or issues that one might get faced within the process. iCliQ Solution was looking to build and gain trust with their clients.</p>
                    <p>Sharedpro with their thorough background check and networking was able to get iCliQ Solution some very trustworthy clients whom they could rely on easily.</p>
                  </li>
                </ul>
              </div>
            </div>
            <div>
            <div className='CTA-card'>
              <img src="" alt="" />
              <h3>Start growing with Sharedpro today</h3>
              <p>With tools to find talent without notice period and a support team eager to help you, make your company more competitive</p>
              <button className='CTA-button'>Get Sharedpro Free</button>
            </div>
            </div>
          </div>

          <div className='result-text'>
            <div className='challenge-text'>
              <h2>03. The Result.</h2>
              <p>With the help of its elite pool of enterprise clients, Sharedpro helped iCliQ Solution build a strong portfolio of high value projects ensuring iCliQ become experts in their domain. Their search for long term projects and reliable clients came to an end with Sharedpro’s platform.</p>
              
            </div>
            <div className="challenge-img">
              <div className='image1'>
                <img src="https://plus.unsplash.com/premium_photo-1661375427386-64ac35086427?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80" alt="" />
              </div>
              <div className='image2'>
                <img src="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80" alt="" />
              </div>
            </div>
          </div>
          <div className='customerwords-card'>
                <div className='customer-img'>
                  <img src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2080&q=80" alt="" />
                </div>
                <div className='customer-text'>
                  <p>With Sharedpro, we have found a great partner who will help us and our candidates find high value projects in no time. The best part is most of their projects are long-term with a smooth onboarding to project extension process</p>
                  <h4>Sankit Koladiya , Owner of Icliq Solution</h4>
                </div>
            </div>
            <div className='CTA-resposive'>
              <img src="" alt="" />
              <h3>Start growing with Sharedpro today</h3>
              <p>With tools to find talent without notice period and a support team eager to help you, make your company more competitive</p>
              <button className='CTA-button'>Get Sharedpro Free</button>
            </div>
        </div>
      </div>
    </>
  );
}

export default readmore;//Export the component to call it anywhere in the application


