import React from 'react'
import {BsChevronDoubleRight} from "react-icons/bs";

//Define functional component for PostItem section
//Return all the blogs which is alredy posted 

const PostItem = ({ imageUrl, author, date, title }) => {
  return (
        <div className="post-item">
          <div className="img-wrapper blog-img">
            <img src={imageUrl} alt="post" />
          </div>
          <div className="posted-by-container">
            <p className="posted-by">By {author}</p>
            <p className="post-date">{date}</p>
          </div>
          <h3>{title}</h3>
          <p>Read more <BsChevronDoubleRight /></p>
        </div>
      
  )
}

export default PostItem