import React, { useEffect } from 'react'
import Card from "./Card.js";
import Carousel from "./Carousel.js";
import image_one from '../../../assets/images/DeployTalent2C.png'
import image_two from '../../../assets/images/get_matched2C.jpg'
import image_three from '../../../assets/images/DeployTalentC.jpg'
import image_four from '../../../assets/images/24C.png'
import image_five from '../../../assets/images/List_profile2C.jpg'
import image_six from '../../../assets/images/share_talentC.png'
import AOS from 'aos';
import 'aos/dist/aos.css';

//Define functional component for HowItworks Section
//Return jsx for UI render
//Contains Card component with image, title, type and description passed as a prop
// Initialize AOS library for animations when the component mounts

function HowItWorks() {
  useEffect(() => {
    AOS.init();
  }, [])

  //This array contains data that is to be passed in the carousel component to be dispalyed.
    let cards = [
        {
          key: 1,
          content: (
            <Card
              imagen={image_one}
              title="Post a job"
              type="Hire"
              desc="Post a requirement with basic details like job description, skills, project tenure etc."
            />
          )
          
        },
        {
          key: 2,
          content: (
            <Card 
            imagen={image_two}
            title="Get Matched"
            type="Hire"
            desc="Get profiles of immediate joiners matching your job. Schedule interview with the profiles you like."

             />
          )
        },
        {
          key: 3,
          content: (
            <Card imagen={image_three}
            title="Deploy Talent"
            type="Hire"
            desc="Post interviews, onboard the selected talent onto your project from the next day itself."

            />
          )
        },
        {
          key: 4,
          content: (
            <Card 
            imagen={image_four}
            title="24*7 Support"
            type="Hire"
            desc="Sharedpro remains in constant touch for any help required till the project is completed."

            />
          )
        },
        {
          key: 5,
          content: (
            <Card 
            imagen={image_five}
            title="List Profile"
            type="Share"
            desc="Auto-create your employee's profile by just uploading a CV with full control over the details shared."
            />
          )
        },
        {
          key: 6,
          content: (
            <Card 
            imagen={image_two}
            title="Get Matched"
            type="Share"
            desc="Our software shows best profiles to the client. You get notified once your listed profile gets shortlisted."
            />
          )
        },
        {
          key: 7,
          content: (
            <Card 
            imagen={image_six}
            title="Share Talent"
            type="Share"
            desc="Post selection, deploy your talent onto the client's project on contract and start earning from day one."
            />
          )
        },
        {
            key: 8,
            content: (
              <Card 
              imagen={image_four}
              title="24*7 Support"
              type="Share"
              desc="From legal help to timely payments, be rest assured with 24*7 phone/email support."
  
              />
            )
          },

      ];
  return (
    <div className='howitWorkMainContainer'>
    <h1 className='howItworksHeading'>How it works to Hire and Share a talent</h1>
    <div className="howItwork_container flex__center" data-aos="zoom-in">

    {/* In Carousel component card array is passed as a props */}
    <Carousel
      cards={cards}
      height="500px"
      width="30%"
      margin="0 auto"
      offset={2}
      showArrows={false}
    />
  </div>
  </div>
  )
}

export default HowItWorks




