import React from 'react'
import './BlogDetail.css'
import  Subscribe from '../../../components/subscribe/Subscribe'
import { useEffect } from 'react';
// import Metadata from '../../../components/Navbar/Metadata';

//Define functional component for BolgDetail page
//Return jsx for UI render
const popularPostsData = [
    {
      imageUrl:
        "https://paradox-react.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fblog_image_8.fe71b0e3.jpg&w=750&q=75",
      date: "Sep 12, 2019",
      title: "Why great employer branding attracts better talent?",
    },
    {
      imageUrl:
        "https://paradox-react.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fblog_image_8.fe71b0e3.jpg&w=750&q=75",
      date: "Sep 12, 2019",
      title: "Why great employer branding attracts better talent?",
    },
    {
      imageUrl:
        "https://paradox-react.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fblog_image_8.fe71b0e3.jpg&w=750&q=75",
      date: "Sep 12, 2019",
      title: "Why great employer branding attracts better talent?",
    },
    {
      imageUrl:
        "https://paradox-react.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fblog_image_8.fe71b0e3.jpg&w=750&q=75",
      date: "Sep 12, 2019",
      title: "Why great employer branding attracts better talent?",
    },
    {
      imageUrl:
        "https://paradox-react.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fblog_image_8.fe71b0e3.jpg&w=750&q=75",
      date: "Sep 12, 2019",
      title: "Why great employer branding attracts better talent?",
    },
    {
      imageUrl:
        "https://paradox-react.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fblog_image_8.fe71b0e3.jpg&w=750&q=75",
      date: "Sep 12, 2019",
      title: "Why great employer branding attracts better talent?",
    },
    {
      imageUrl:
        "https://paradox-react.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fblog_image_8.fe71b0e3.jpg&w=750&q=75",
      date: "Sep 12, 2019",
      title: "Why great employer branding attracts better talent?",
    },
  ];

  const blogDetail = 
    {
      imageUrl:
        "https://paradox-react.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fblog_image_1.38f32bce.jpg&w=1200&q=75",
      date: "23 Sept 2023",
      title: "CIIE.CO, BMGF, NPCI launch Grand Challenge to enable digital payments for feature phones",
      description: "CIIE.CO, the National Payments Corporation of India (NPCI), and the Bill & Melinda Gates Foundation have come together to launch a Grand Challenge to bring innovators from around the world to submit ideas and solutions that can enable feature phone users in India to transact easily and securely. According to a statement, the challenge seeks solutions that are customised for feature phones with simplified self-onboarding of users, enhanced user experience, highest security standards, and automated dispute resolution mechanisms.",
    }


// A functional component for rendering a single popular post.
// passed image-url, date, and title. 
  const PopularPost = ({ imageUrl, date, title }) => (
    <div className="popular-post-item">
      <div className="img-wrapper">
        <img src={imageUrl} alt="popular-post" />
      </div>
      <div className="popular-post-content">
        <p className='blog-date'>{date}</p>
        <p>{title}</p>
      </div>
    </div>
  );
  


// main blogDetail component
const BlogDetail = () => {

    // to set the title of the page
    useEffect(()=>{
      document.title = `${blogDetail.title}|Sharedpro`;
    },[])
{/* <Metadata title={`${blogDetail.title} | Sharedpro`} /> */}

  return (
    <div className="blog-detail-container">
    <div className="blog-header flex__center">
    <div className="blog-title">
        <h1>Blog Details</h1>
    </div>
    </div>

    <div className="bolg-details-main-section">

      {/* left-side-container */}
      <div className='blog-detials-left-section'>
    <div className="post-details-item-container">
    <div className="post-detail-item">
          <div className="img-wrapper">
            <img src={blogDetail.imageUrl} alt="post" />
          </div>
    </div>
    <div className="blog-detail-posted-by-container">
            <p className="posted-by">By Urmi jain</p>
            <p className="post-date">23 sept 2023</p>
        </div>
          <h3>{blogDetail.title}</h3>
          <p className='blog-description'>
            {blogDetail.description}
          </p>
   </div>
   </div>


    {/* right-side-contianer */}
    <div className='blog-detail-right-sidebar'>
   <div className="from-the-blog-section">
    <h3>From The Blog</h3>
    <p>We create amazing Webflow templates for creative people all around the world and help brands stand out.</p>
</div>
<div className="popular-posts">
<h3>Popular Post</h3>
<div className="popular-post-container">
{/* Mapping through popularPostsData to render PopularPost components. */}
{popularPostsData.map((post, index) => (
                <PopularPost
                  key={index}
                  imageUrl={post.imageUrl}
                  date={post.date}
                  title={post.title}
                />
              ))}
</div>
</div>
   </div>
    </div>

    {/* called the subscribe us component */}
    <Subscribe showClient={false} />
    </div>
  )
}

export default BlogDetail;