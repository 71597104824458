import React from "react";
import './PrivacyandTermsCard.css'
import { Link } from "react-router-dom";

//define privacyandtermscard component used in privacy and terms page
//returns  jsx to render UI
const privacyandtermscard = () => {
    return(
        <div className="termscard">
        <ul>
            <li>
                <div >
                    <Link to='/privacypolicy'>
                        <a>Privacy Policy </a></Link>
                </div>
            </li>
            <li>
                <div>
                    <Link to='/termsncondi'>
                        <a>Terms and Condition</a></Link>
                </div>
            </li>
            <li>
                <div>
                    <Link to="/informationsecurity"><a>InformationSecurity</a></Link>
                </div>
            </li>
        </ul>
    </div>
    )
    
}
export default privacyandtermscard;